.leaflet-container {
	font-family: 'Kanit' !important;
	font-size: 12px !important;
	border: 0 !important;
}

.leaflet-touch .leaflet-control-layers {
  border: 0 !important;
  color: #576469 !important;
  box-shadow: var(--box-shadow-lg) !important;
}

.leaflet-touch .leaflet-bar {
	border: 0 !important;
	margin-bottom: 20px;

	a {
		background-color: #0d6a92;
		color: white;
		box-shadow: var(--box-shadow-lg);
		border: 0;

		&:hover {
			background-color: #3e9536;
		}

		&:first-child {
			border-top-left-radius: 5px !important;
			border-top-right-radius: 5px !important;
		}

		&:last-child {
			border-bottom-left-radius: 5px !important;
			border-bottom-right-radius: 5px !important;
		}
	}
}

.leaflet-control-layers-list {
  padding: 5px;
}

.leaflet-control-layers-base > label > span {
  display: flex;
  align-content: center;
  margin-bottom: 2px;
}

.leaflet-control-layers-overlays > label > span {
  display: flex;
  align-content: center;
  margin-bottom: 2px;
}

.leaflet-control-layers-selector {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #D4D4D4;
  border-radius: 50%;
  background-color: #D4D4D4;
  outline: none;
  cursor: pointer;
  font-family: Phosphor;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: 0;

  &:checked {
    background-color: #76BDE9;
    border: 2px solid #76BDE9;

    &::after {
      content: '\f33e'; // check
      color: white;
      font-weight: 800;
    }
  }
}

.leaflet-control-layers-separator {
  margin: 10px 0 !important;
}
