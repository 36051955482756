/* ------------------------------------------------------------------------------
 *
 *  # User profile
 *
 *  Styles for all user profile layouts
 *
 * ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-profile {
	// Cover image layout base
	.profile-cover {
		--#{$prefix}profile-cover-height: #{$profile-cover-height};
		--#{$prefix}profile-cover-text-shadow: #{$profile-cover-text-shadow};

		position: relative;
	}

	// User details
	.profile-cover-text {
		text-shadow: var(--#{$prefix}profile-cover-text-shadow);
	}

	// Cover image
	.profile-cover-img {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		height: var(--#{$prefix}profile-cover-height);
	}
}
