/* ------------------------------------------------------------------------------
 *
 *  # Custom Limitless functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */

//
// Round a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @param  {String} [$mode:round] (round|ceil|floor) How to round a number
// @return {Number} A rounded number
// @example
//     decimal-round(0.333)    => 0
//     decimal-round(0.333, 1) => 0.3
//     decimal-round(0.333, 2) => 0.33
//     decimal-round(0.666)    => 1
//     decimal-round(0.666, 1) => 0.7
//     decimal-round(0.666, 2) => 0.67
//

@function decimal-round($number, $digits: 0, $mode: round) {
	$n: 1;
	// $number must be a number
	@if type-of($number) != number {
		@warn '#{ $number } is not a number.';
		@return $number;
	}
	// $digits must be a unitless number
	@if type-of($digits) != number {
		@warn '#{ $digits } is not a number.';
		@return $number;
	} @else if not unitless($digits) {
		@warn '#{ $digits } has a unit.';
		@return $number;
	}
	@for $i from 1 through $digits {
		$n: $n * 10;
	}
	@if $mode == round {
		@return calc(#{round($number * $n)} / #{$n});
	} @else if $mode == ceil {
		@return calc(#{ceil($number * $n)} / #{$n});
	} @else if $mode == floor {
		@return calc(#{floor($number * $n)} / #{$n});
	} @else {
		@warn '#{ $mode } is undefined keyword.';
		@return $number;
	}
}

// Ceil a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A ceiled number
// @example
//     decimal-ceil(0.333)    => 1
//     decimal-ceil(0.333, 1) => 0.4
//     decimal-ceil(0.333, 2) => 0.34
//     decimal-ceil(0.666)    => 1
//     decimal-ceil(0.666, 1) => 0.7
//     decimal-ceil(0.666, 2) => 0.67
//

@function decimal-ceil($number, $digits: 0) {
	@return decimal-round($number, $digits, ceil);
}

// Floor a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A floored number
// @example
//     decimal-floor(0.333)    => 0
//     decimal-floor(0.333, 1) => 0.3
//     decimal-floor(0.333, 2) => 0.33
//     decimal-floor(0.666)    => 0
//     decimal-floor(0.666, 1) => 0.6
//     decimal-floor(0.666, 2) => 0.66
//

@function decimal-floor($number, $digits: 0) {
	@return decimal-round($number, $digits, floor);
}
