.mat-calendar-body-selected {
  background-color: #247297;
  color: #fff;
}

.custom-form-field {
  width: 100%;
  height: 38px!important;
}
.custom-form-field .mat-mdc-text-field-wrapper {
  height: 38px;
  flex: auto;

}
.custom-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 4px;
}

.mat-mdc-text-field-wrapper {
  height: 38px!important;
}

.custom-form-field .mat-mdc-form-field-infix {
  flex: auto;
  min-width: 0;
  width: 480px;
  position: relative;
  box-sizing: border-box;
  padding-right: 50px;
}
.custom-form-field .mat-datepicker-toggle {
  position: absolute;
  top: calc(20% - 12px);
  right: 5px;
}
.custom-form-field .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font: inherit;
  border: none;
  font-size: 14px;
}
.custom-form-field  .mat-form-field-wrapper {
  border: 1px solid #ced4da;
}
