// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

// Default buttons
@mixin button-variant(
	$background,
	$border,
	$color: color-contrast($background),
	$hover-background:
		if(
			$color == $color-contrast-light,
			shade-color($background, $btn-hover-bg-shade-amount),
			shade-color($background, $btn-hover-bg-tint-amount)
		),
	$hover-border:
		if(
			$color == $color-contrast-light,
			shade-color($border, $btn-hover-border-shade-amount),
			shade-color($border, $btn-hover-border-tint-amount)
		),
	$hover-color: color-contrast($hover-background),
	$active-background:
		if(
			$color == $color-contrast-light,
			shade-color($background, $btn-active-bg-shade-amount),
			shade-color($background, $btn-active-bg-tint-amount)
		),
	$active-border:
		if(
			$color == $color-contrast-light,
			shade-color($border, $btn-active-border-shade-amount),
			shade-color($border, $btn-active-border-tint-amount)
		),
	$active-color: color-contrast($active-background),
	$disabled-background: $background,
	$disabled-border: $border,
	$disabled-color: color-contrast($disabled-background)
) {
	@if ($background == $light) {
		--#{$prefix}btn-color: #{$btn-light-color};
		--#{$prefix}btn-bg: #{$btn-light-bg};
		--#{$prefix}btn-border-color: #{$btn-light-border-color};
		--#{$prefix}btn-hover-color: #{$btn-light-hover-color};
		--#{$prefix}btn-hover-bg: #{$btn-light-hover-bg};
		--#{$prefix}btn-hover-border-color: #{$btn-light-hover-border-color};
		--#{$prefix}btn-focus-shadow-rgb: #{to-rgb(mix($color, $border, 15%))};
		--#{$prefix}btn-active-color: #{$btn-light-active-color};
		--#{$prefix}btn-active-bg: #{$btn-light-active-bg};
		--#{$prefix}btn-active-border-color: #{$btn-light-active-border-color};
		--#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
		--#{$prefix}btn-disabled-color: #{$btn-light-disabled-color};
		--#{$prefix}btn-disabled-bg: #{$btn-light-disabled-bg};
		--#{$prefix}btn-disabled-border-color: #{$btn-light-disabled-border-color};
	} @else {
		--#{$prefix}btn-color: #{$color};
		--#{$prefix}btn-bg: #{$background};
		--#{$prefix}btn-border-color: #{$border};
		--#{$prefix}btn-hover-color: #{$hover-color};
		--#{$prefix}btn-hover-bg: #{$hover-background};
		--#{$prefix}btn-hover-border-color: #{$hover-border};
		--#{$prefix}btn-focus-shadow-rgb: #{to-rgb(mix($color, $border, 15%))};
		--#{$prefix}btn-active-color: #{$active-color};
		--#{$prefix}btn-active-bg: #{$active-background};
		--#{$prefix}btn-active-border-color: #{$active-border};
		--#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
		--#{$prefix}btn-disabled-color: #{$disabled-color};
		--#{$prefix}btn-disabled-bg: #{$disabled-background};
		--#{$prefix}btn-disabled-border-color: #{$disabled-border};
	}
}

@mixin button-outline-variant(
	$color,
	$color-hover: color-contrast($color),
	$active-background: $color,
	$active-border: $color,
	$active-color: color-contrast($active-background)
) {
	@if ($active-background == $light) {
		--#{$prefix}btn-color: #{$btn-light-color};
		--#{$prefix}btn-border-color: #{$btn-light-border-color};
		--#{$prefix}btn-hover-color: #{$btn-light-hover-color};
		--#{$prefix}btn-hover-bg: #{$btn-light-hover-bg};
		--#{$prefix}btn-hover-border-color: #{$btn-light-hover-border-color};
		--#{$prefix}btn-focus-shadow-rgb: #{to-rgb($color)};
		--#{$prefix}btn-active-color: #{$btn-light-active-color};
		--#{$prefix}btn-active-bg: #{$btn-light-active-bg};
		--#{$prefix}btn-active-border-color: #{$btn-light-active-border-color};
		--#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
		--#{$prefix}btn-disabled-color: #{$btn-light-disabled-color};
		--#{$prefix}btn-disabled-bg: #{$btn-light-disabled-bg};
	} @else {
		--#{$prefix}btn-color: #{$color};
		--#{$prefix}btn-border-color: #{$color};
		--#{$prefix}btn-hover-color: #{$color-hover};
		--#{$prefix}btn-hover-bg: #{$active-background};
		--#{$prefix}btn-hover-border-color: #{$active-border};
		--#{$prefix}btn-focus-shadow-rgb: #{to-rgb($color)};
		--#{$prefix}btn-active-color: #{$active-color};
		--#{$prefix}btn-active-bg: #{darken($active-background, 5%)};
		--#{$prefix}btn-active-border-color: #{$active-border};
		--#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
		--#{$prefix}btn-disabled-color: #{$color};
		--#{$prefix}btn-disabled-bg: transparent;
		--#{$prefix}gradient: none;
	}
}

// Flat buttons
@mixin button-flat-variant($background, $color) {
	--#{$prefix}btn-bg: rgba(#{var(--#{$prefix}#{$background}-rgb)}, 0.1);
	--#{$prefix}btn-color: var(--#{$prefix}#{$background});
	--#{$prefix}btn-border-color: var(--#{$prefix}#{$background});
	--#{$prefix}btn-hover-color: var(--#{$prefix}btn-color);
	--#{$prefix}btn-hover-bg: rgba(#{var(--#{$prefix}#{$background}-rgb)}, 0.2);
	--#{$prefix}btn-hover-border-color: var(--#{$prefix}#{$background});
	--#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}#{$background}-rgb);
	--#{$prefix}btn-active-color: var(--#{$prefix}#{$background});
	--#{$prefix}btn-active-bg: rgba(#{var(--#{$prefix}#{$background}-rgb)}, 0.25);
	--#{$prefix}btn-active-border-color: var(--#{$prefix}#{$background});
	--#{$prefix}btn-disabled-color: rgba(
		#{var(--#{$prefix}#{$background}-rgb)},
		0.65
	);
	--#{$prefix}btn-disabled-bg: rgba(
		#{var(--#{$prefix}#{$background}-rgb)},
		0.05
	);
}
