// Range
//
// Style range inputs the same across browsers. Vendor-specific rules for pseudo
// elements cannot be mixed. As such, there are no shared styles for focus or
// active states on prefixed selectors.

.form-range {
	--#{$prefix}form-range-thumb-width: #{$form-range-thumb-width};
	--#{$prefix}form-range-thumb-height: #{$form-range-thumb-height};
	--#{$prefix}form-range-thumb-focus-box-shadow-width: #{$form-range-thumb-focus-box-shadow-width};
	--#{$prefix}form-range-thumb-focus-box-shadow: #{$form-range-thumb-focus-box-shadow};
	--#{$prefix}form-range-thumb-bg: #{$form-range-thumb-bg};
	--#{$prefix}form-range-thumb-border: #{$form-range-thumb-border};
	--#{$prefix}form-range-thumb-border-radius: #{$form-range-thumb-border-radius};
	--#{$prefix}form-range-thumb-box-shadow: #{$form-range-thumb-box-shadow};
	--#{$prefix}form-range-thumb-active-bg: #{$form-range-thumb-active-bg};
	--#{$prefix}form-range-thumb-disabled-bg: #{$form-range-thumb-disabled-bg};
	--#{$prefix}form-range-track-width: #{$form-range-track-width};
	--#{$prefix}form-range-track-height: #{$form-range-track-height};
	--#{$prefix}form-range-track-bg: #{$form-range-track-bg};
	--#{$prefix}form-range-track-border-radius: #{$form-range-track-border-radius};
	--#{$prefix}form-range-track-box-shadow: #{$form-range-track-box-shadow};

	width: 100%;
	height: add(
		--#{$prefix}form-range-thumb-height,
		calc(var(--#{$prefix}form-range-thumb-focus-box-shadow-width) * 2)
	);
	padding: 0; // Need to reset padding
	background-color: transparent;
	appearance: none;

	&:focus {
		outline: 0;

		// Pseudo-elements must be split across multiple rulesets to have an effect.
		// No box-shadow() mixin for focus accessibility.
		&::-webkit-slider-thumb {
			box-shadow: var(--#{$prefix}form-range-thumb-focus-box-shadow);
		}
		&::-moz-range-thumb {
			box-shadow: var(--#{$prefix}form-range-thumb-focus-box-shadow);
		}
	}

	&::-moz-focus-outer {
		border: 0;
	}

	&::-webkit-slider-thumb {
		width: var(--#{$prefix}form-range-thumb-width);
		height: var(--#{$prefix}form-range-thumb-height);
		margin-top: calc(
			calc(
					var(--#{$prefix}form-range-track-height) -
						var(--#{$prefix}form-range-thumb-height)
				) * 0.5
		); // Webkit specific
		@include gradient-bg(var(--#{$prefix}form-range-thumb-bg));
		border: var(--#{$prefix}form-range-thumb-border);
		@include border-radius(var(--#{$prefix}form-range-thumb-border-radius));
		@include box-shadow(var(--#{$prefix}form-range-thumb-box-shadow));
		@include transition($form-range-thumb-transition);
		appearance: none;

		&:active {
			@include gradient-bg(var(--#{$prefix}form-range-thumb-active-bg));
		}
	}

	&::-webkit-slider-runnable-track {
		width: var(--#{$prefix}form-range-track-width);
		height: var(--#{$prefix}form-range-track-height);
		color: transparent; // Why?
		cursor: $form-range-track-cursor;
		background-color: var(--#{$prefix}form-range-track-bg);
		border-color: transparent;
		@include border-radius(var(--#{$prefix}form-range-track-border-radius));
		@include box-shadow(var(--#{$prefix}form-range-track-box-shadow));
	}

	&::-moz-range-thumb {
		width: var(--#{$prefix}form-range-thumb-width);
		height: var(--#{$prefix}form-range-thumb-height);
		@include gradient-bg(var(--#{$prefix}form-range-thumb-bg));
		border: var(--#{$prefix}form-range-thumb-border);
		@include border-radius(var(--#{$prefix}form-range-thumb-border-radius));
		@include box-shadow(var(--#{$prefix}form-range-thumb-box-shadow));
		@include transition($form-range-thumb-transition);
		appearance: none;

		&:active {
			@include gradient-bg(var(--#{$prefix}form-range-thumb-active-bg));
		}
	}

	&::-moz-range-track {
		width: var(--#{$prefix}form-range-track-width);
		height: var(--#{$prefix}form-range-track-height);
		color: transparent;
		cursor: $form-range-track-cursor;
		background-color: var(--#{$prefix}form-range-track-bg);
		border-color: transparent; // Firefox specific?
		@include border-radius(var(--#{$prefix}form-range-track-border-radius));
		@include box-shadow(var(--#{$prefix}form-range-track-box-shadow));
	}

	&:disabled {
		pointer-events: none;

		&::-webkit-slider-thumb {
			background-color: var(--#{$prefix}form-range-thumb-disabled-bg);
		}

		&::-moz-range-thumb {
			background-color: var(--#{$prefix}form-range-thumb-disabled-bg);
		}
	}
}
