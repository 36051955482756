@import 'phosphor-icons/src/css/icons.css';

@font-face {
	font-family: 'Phosphor';
	src:
		url('~node_modules/phosphor-icons/src/fonts/Phosphor.ttf?9jjo3j')
			format('truetype'),
		url('~node_modules/phosphor-icons/src/fonts/Phosphor.woff?9jjo3j')
			format('woff'),
		url('~node_modules/phosphor-icons/src/fonts/Phosphor.svg?9jjo3j#Phosphor')
			format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class*=' ph-'],
[class^='ph-'] {
	font-family: Phosphor, serif !important;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: var(--icon-font-size);
	min-width: 1em;
	display: inline-block;
	vertical-align: calc(calc(50% - var(--icon-font-size)) * 0.5);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ph-1x {
	font-size: calc(1 * var(--icon-font-size));
}
.ph-2x {
	font-size: calc(2 * var(--icon-font-size));
}
.ph-3x {
	font-size: calc(3 * var(--icon-font-size));
}
.ph-4x {
	font-size: calc(4 * var(--icon-font-size));
}
.ph-5x {
	font-size: calc(5 * var(--icon-font-size));
}
.ph-lg {
	font-size: var(--icon-font-size-lg);
}
.ph-sm {
	font-size: var(--icon-font-size-sm);
}
