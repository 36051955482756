/* ------------------------------------------------------------------------------
 *
 *  # Responsive extension
 *
 *  Optimise the table's layout for different screen sizes through the dynamic 
 *  insertion and removal of columns from the table
 *
 * ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-datatable-responsive {
	// Inline details row
	.dtr-inline {
		&.collapsed tbody {
			tr {
				td,
				th {
					&:before {
						margin-right: var(--#{$prefix}spacer-2);
					}

					&:first-child {
						position: relative;
						cursor: pointer;
						white-space: nowrap;

						&.dataTables_empty:before {
							display: none;
						}
					}
				}
			}
		}
	}

	// Column details row
	.dtr-column {
		tbody {
			td,
			th {
				&.control {
					position: relative;
					cursor: pointer;
				}
			}
		}
	}

	//
	// Expand/collapse icons
	//

	.dtr-inline.collapsed tbody tr td:first-child:before,
	.dtr-inline.collapsed tbody tr th:first-child:before,
	.dtr-column tbody tr td.control:before,
	.dtr-column tbody tr th.control:before {
		content: $icon-table-row-expand;
		font-family: var(--#{$prefix}icon-font-family);
		display: inline-block;
		font-size: var(--#{$prefix}icon-font-size);
		width: var(--#{$prefix}icon-font-size);
		line-height: 1;
		position: relative;
		top: calc(
			calc(
					var(--#{$prefix}icon-font-size) -
						var(--#{$prefix}body-line-height-computed)
				) *
				0.5
		);
		vertical-align: middle;
		@include ll-font-smoothing();
	}
	.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before,
	.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before,
	.dataTable.dtr-column tbody tr.parent td.control:before,
	.dataTable.dtr-column tbody tr.parent th.control:before {
		content: $icon-table-row-collapse;
	}
	.dtr-inline.collapsed tbody tr.child td:before {
		display: none;
	}

	//
	// Child rows with details
	//

	.dataTable {
		tr {
			// Child row
			&.child {
				&:hover {
					background-color: transparent;
				}

				.dtr-title {
					display: table-cell;
					font-weight: $font-weight-semibold;
					padding-right: calc(var(--#{$prefix}spacer) * 2);
				}

				.dtr-data {
					display: table-cell;
					padding: var(--#{$prefix}spacer-2) 0;
					white-space: normal;
				}
			}

			// Child cell
			td.child {
				white-space: normal;
				position: relative;

				> ul {
					display: table;
					table-layout: fixed;
					width: 100%;
					list-style: none;
					margin: 0;
					padding: 0;

					> li {
						display: table-row;
					}
				}
			}
		}
	}
}
