/* ------------------------------------------------------------------------------
*
*  # Trumbowyg editor
*
*  Styles for trumbowyg.min.js - a lightweight WYSIWYG editor
*
* ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-trumbowyg {
	//
	// Base
	//

	// Configure variables
	.trumbowyg-box {
		--#{$prefix}tw-min-height: #{$trumbowyg-min-height};
		--#{$prefix}tw-bg: #{$card-bg};
		--#{$prefix}tw-dropdown-bg: #{$dropdown-bg};
		--#{$prefix}tw-dropdown-border-width: #{$dropdown-border-width};
		--#{$prefix}tw-dropdown-border-color: #{$dropdown-border-color};
		--#{$prefix}tw-dropdown-border-radius: #{$dropdown-border-radius};
		--#{$prefix}tw-dropdown-box-shadow: #{$dropdown-box-shadow};
		--#{$prefix}tw-dropdown-link-color: #{$dropdown-link-color};
		--#{$prefix}tw-dropdown-link-hover-color: #{$dropdown-link-hover-color};
		--#{$prefix}tw-dropdown-link-hover-bg: #{$dropdown-link-hover-bg};
		--#{$prefix}tw-dropdown-link-active-color: #{$dropdown-link-active-color};
		--#{$prefix}tw-dropdown-link-active-bg: #{$dropdown-link-active-bg};

		// Dark theme
		@include color-scheme(dark) {
			--#{$prefix}tw-bg: #{$card-darkmode-bg};
			--#{$prefix}tw-dropdown-bg: #{$dropdown-darkmode-bg};
			--#{$prefix}tw-dropdown-border-color: #{$dropdown-darkmode-border-color};
		}
	}

	// Container
	.trumbowyg-box {
		position: relative;

		// SVG icons
		svg {
			fill: var(--#{$prefix}body-color);
			display: block;
			width: var(--#{$prefix}icon-font-size);
			height: var(--#{$prefix}icon-font-size);
		}

		// Fullscreen mode
		&.trumbowyg-fullscreen {
			background: var(--#{$prefix}tw-bg);
			border-width: 0;
		}
	}

	// Icons
	#trumbowyg-icons {
		overflow: hidden;
		visibility: hidden;
		height: 0;
		width: 0;

		svg {
			height: 0;
			width: 0;
		}
	}

	//
	// Content area
	//

	// Editor
	.trumbowyg-editor {
		position: relative;

		// Placeholder
		&[contenteditable='true']:empty:not(:focus)::before {
			content: attr(placeholder);
			color: $text-muted;
			pointer-events: none;
		}
	}

	// Common styles
	.trumbowyg-editor,
	.trumbowyg-textarea {
		position: relative;
		padding: var(--#{$prefix}spacer);
		min-height: var(--#{$prefix}tw-min-height);
		width: 100%;
		border: 0;
		color: var(--#{$prefix}body-color);
		resize: none;
		background-color: transparent;
		outline: 0;
		overflow: auto;
	}

	// Blur
	.trumbowyg-box-blur {
		.trumbowyg-editor {
			img,
			hr {
				opacity: 0.2;
			}
		}
	}

	// Textarea
	.trumbowyg-textarea {
		position: relative;
		display: block;
		overflow: auto;
		border: 0;
		white-space: normal;
	}

	//
	// States
	//

	.trumbowyg-box {
		// Visible
		&.trumbowyg-editor-visible {
			// Hide textarea
			.trumbowyg-textarea {
				height: 1px !important;
				width: 25%;
				min-height: 0 !important;
				padding: 0 !important;
				background: none;
				opacity: 0 !important;
			}
		}

		// Hidden
		&.trumbowyg-editor-hidden {
			// Show textarea
			.trumbowyg-textarea {
				display: block;
			}

			// And hide editor
			.trumbowyg-editor {
				display: none;
			}
		}

		// Disabled
		&.trumbowyg-disabled {
			.trumbowyg-textarea {
				opacity: 0.65;
				background-color: transparent;
			}
		}
	}

	//
	// Toolbar
	//

	.trumbowyg-button-pane {
		width: 100%;
		border-bottom: var(--#{$prefix}border-width)
			solid
			var(--#{$prefix}border-color);
		margin: 0;
		padding-top: var(--#{$prefix}spacer-2);
		padding-left: var(--#{$prefix}spacer-2);
		padding-right: var(--#{$prefix}spacer-2);
		position: relative;
		list-style: none;
		backface-visibility: hidden;

		// Button group
		.trumbowyg-button-group {
			display: inline-block;
			position: relative;
			margin-bottom: var(--#{$prefix}spacer-2);

			// Vertical border
			+ .trumbowyg-button-group:not(.trumbowyg-right) {
				margin-left: var(--#{$prefix}spacer-2);
				padding-left: var(--#{$prefix}spacer-2);
				border-left: var(--#{$prefix}border-width)
					solid
					var(--#{$prefix}border-color);
			}

			// Spacing between buttons
			button + button {
				margin-left: var(--#{$prefix}spacer-1);
			}

			// Fullscreen button
			.trumbowyg-fullscreen-button {
				svg {
					color: transparent;
				}
			}
		}

		// Buttons
		button {
			display: inline-block;
			position: relative;
			text-align: center;
			vertical-align: middle;
			user-select: none;
			background-color: transparent;
			padding: var(--#{$prefix}spacer-1);
			border: var(--#{$prefix}border-width) solid transparent;
			@include border-radius(var(--#{$prefix}border-radius));
			@include transition(
				background-color ease-in-out var(--#{$prefix}transition-base-timer)
			);

			// Hover state
			&:hover,
			&:focus {
				background-color: var(--#{$prefix}gray-200);
			}

			// Active state
			&:active,
			&.trumbowyg-active {
				background-color: var(--#{$prefix}gray-300);
			}
		}

		// Disabled state
		&.trumbowyg-disable,
		.trumbowyg-disabled & {
			button:not(.trumbowyg-not-disable) {
				&:not(.trumbowyg-active),
				&:not(.trumbowyg-viewHTML-button) {
					opacity: $btn-disabled-opacity;
					cursor: default;
					pointer-events: none;
				}
			}
		}

		// Dropdown toggles
		.trumbowyg-open-dropdown {
			padding-right: calc(
				calc(var(--#{$prefix}spacer-2) + var(--#{$prefix}spacer-1)) + 0.3em
			);

			// Caret
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				right: calc(var(--#{$prefix}spacer-1) + 0.3em);
				height: 0;
				width: 0;
				border: 0.3em solid transparent;
				border-top-color: currentcolor;
				transform: translateY(-25%);
			}
		}

		// Right aligned
		.trumbowyg-right {
			float: right;
		}
	}

	//
	// Toolbar dropdowns
	//

	.trumbowyg-dropdown {
		min-width: $dropdown-min-width;
		padding: $dropdown-padding-y 0;
		background-color: var(--#{$prefix}tw-dropdown-bg);
		border: var(--#{$prefix}tw-dropdown-border-width)
			solid
			var(--#{$prefix}tw-dropdown-border-color);
		margin-top: $dropdown-spacer;
		z-index: $zindex-dropdown;
		@include box-shadow(var(--#{$prefix}tw-dropdown-box-shadow));
		@include border-radius(var(--#{$prefix}tw-dropdown-border-radius));

		// Dropdown item
		button {
			display: block;
			width: 100%;
			padding: $dropdown-item-padding-y $dropdown-item-padding-x;
			color: var(--#{$prefix}tw-dropdown-link-color);
			background-color: transparent;
			white-space: nowrap;
			border: 0;
			text-align: inherit;
			cursor: pointer;
			@include transition(
				background-color ease-in-out var(--#{$prefix}transition-base-timer),
				color ease-in-out var(--#{$prefix}transition-base-timer)
			);

			// Hover state
			&:hover,
			&:focus {
				color: var(--#{$prefix}tw-dropdown-link-hover-color);
				@include gradient-bg(var(--#{$prefix}tw-dropdown-link-hover-bg));
			}

			// Active state
			&:active {
				color: var(--#{$prefix}tw-dropdown-link-active-color);
				@include gradient-bg(var(--#{$prefix}tw-dropdown-link-active-bg));
			}

			// Icon
			svg {
				display: inline-block;
				vertical-align: middle;
				margin-right: $dropdown-item-padding-x;
				margin-top: calc(
					calc(
							var(--#{$prefix}body-font-size) - var(--#{$prefix}icon-font-sise)
						) *
						0.5
				);
			}
		}
	}

	//
	// Modal box
	//

	// Container
	.trumbowyg-modal {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		max-width: 33rem;
		width: 100%;
		z-index: 11;
		backface-visibility: hidden;
	}

	// Box
	.trumbowyg-modal-box {
		--#{$prefix}tw-modal-bg: #{$modal-content-bg};
		--#{$prefix}tw-modal-border-width: #{$modal-content-border-width};
		--#{$prefix}tw-modal-border-color: #{$modal-content-border-color};
		--#{$prefix}tw-modal-box-shadow: #{$modal-content-box-shadow-sm-up};

		// Dark theme
		@include color-scheme(dark) {
			--#{$prefix}tw-modal-bg: #{$modal-content-darkmode-bg};
		}

		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		max-width: 30rem;
		width: calc(100% - 3rem);
		background-color: var(--#{$prefix}tw-modal-bg);
		border: var(--#{$prefix}tw-modal-border-width)
			solid
			var(--#{$prefix}tw-modal-border-color);
		z-index: 1;
		background-clip: padding-box;
		backface-visibility: hidden;
		@include border-radius($modal-content-border-radius);
		@include box-shadow(var(--#{$prefix}tw-modal-box-shadow));

		// Title
		.trumbowyg-modal-title {
			font-size: $h6-font-size;
			font-weight: $font-weight-semibold;
			padding: $modal-header-padding;
			padding-bottom: 0;
			display: block;
		}

		// Progress
		.trumbowyg-progress {
			width: 100%;
			height: 0.1875rem;
			position: absolute;
			top: $trumbowyg-toolbar-height;

			.trumbowyg-progress-bar {
				background-color: var(--#{$prefix}success);
				width: 0;
				height: 100%;
				@include transition(
					width linear var(--#{$prefix}transition-base-timer)
				);
			}
		}

		// Form elements
		form {
			padding: $modal-inner-padding;
			text-align: right;

			// Text
			.trumbowyg-input-infos {
				display: block;
				text-align: left;
				@include transition(
					all ease-in-out var(--#{$prefix}transition-base-timer)
				);

				label {
					display: block;
					position: relative;
					margin-bottom: var(--#{$prefix}spacer-2);

					@include media-breakpoint-up(md) {
						margin-bottom: 0;
					}
				}

				span {
					display: block;
					line-height: $input-btn-line-height;

					@include media-breakpoint-up(md) {
						padding-top: add($input-padding-y, $input-border-width);
						padding-bottom: add($input-padding-y, $input-border-width);
					}

					&.trumbowyg-msg-error {
						color: var(--#{$prefix}danger);
						margin-left: 30%;
						padding-bottom: 0;
					}
				}

				// Error
				.trumbowyg-input-error {
					input,
					textarea {
						border-color: var(--#{$prefix}danger);
					}
				}
			}

			// Inputs
			.trumbowyg-input-html {
				input {
					--#{$prefix}tw-input-bg: #{$input-bg};
					--#{$prefix}tw-input-color: #{$input-color};
					--#{$prefix}tw-input-border-width: #{$input-border-width};
					--#{$prefix}tw-input-border-color: #{$input-border-color};
					--#{$prefix}tw-input-focus-bg: #{$input-focus-bg};
					--#{$prefix}tw-input-focus-border-color: #{$input-focus-border-color};

					// Dark theme
					@include color-scheme(dark) {
						--#{$prefix}tw-input-bg: #{$input-darkmode-bg};
						--#{$prefix}tw-input-focus-bg: #{$input-darkmode-focus-bg};
					}

					// Remove outline
					&:focus {
						outline: 0;
					}

					// Text and file
					&:not([type='checkbox']):not([type='radio']) {
						padding: $input-padding-y $input-padding-x;
						font-family: $input-font-family;
						@include font-size($input-font-size);
						line-height: $input-line-height;
						color: var(--#{$prefix}tw-input-color);
						background-color: var(--#{$prefix}tw-input-bg);
						background-clip: padding-box;
						border: var(--#{$prefix}tw-input-border-width)
							solid
							var(--#{$prefix}tw-input-border-color);
						width: 100%;
						@include border-radius($input-border-radius, 0);
						@include transition($input-transition);

						// Focus state
						&:focus {
							background-color: var(--#{$prefix}tw-input-focus-bg);
							border-color: var(--#{$prefix}tw-input-focus-border-color);
							@include box-shadow($input-focus-box-shadow);
						}
					}

					// Checkbox
					&[type='checkbox'] {
						position: absolute;
						left: 30%;
						top: 50%;
						transform: translate(0, -50%);
					}
				}
			}
		}

		.trumbowyg-input-row {
			margin-bottom: var(--#{$prefix}spacer);
			@include media-breakpoint-up(md) {
				display: grid;
				grid-template-columns: 1fr 2fr;
				grid-gap: var(--#{$prefix}spacer);

				.trumbowyg-input-infos {
					// grid-column: 1 / 2;
				}

				.trumbowyg-input-html {
					// grid-column: 2 / 3;
				}
			}
		}

		// Error message
		.error {
			display: block;
			color: var(--#{$prefix}danger);
		}
	}

	// Buttons
	.trumbowyg-modal-button {
		--#{$prefix}btn-padding-x: #{$btn-padding-x};
		--#{$prefix}btn-padding-y: #{$btn-padding-y};
		--#{$prefix}btn-color: #{$body-color};
		--#{$prefix}btn-bg: transparent;
		--#{$prefix}btn-border-width: #{$btn-border-width};
		--#{$prefix}btn-border-color: transparent;
		--#{$prefix}btn-border-radius: #{$btn-border-radius};
		--#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
		--#{$prefix}btn-focus-box-shadow: 0
			0
			0
			#{$btn-focus-width}
			rgba(var(--#{$prefix}btn-focus-shadow-rgb), 0.25);

		display: inline-block;
		text-align: center;
		color: var(--#{$prefix}btn-color);
		padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
		background-color: var(--#{$prefix}btn-bg);
		text-decoration: if($link-decoration == none, null, none);
		margin-left: var(--#{$prefix}spacer-2);
		vertical-align: middle;
		user-select: none;
		border: var(--#{$prefix}btn-border-width)
			solid
			var(--#{$prefix}btn-border-color);
		@include transition($btn-transition);
		@include border-radius(var(--#{$prefix}btn-border-radius));

		// Hover state
		&:hover {
			color: var(--#{$prefix}btn-hover-color);
			background-color: var(--#{$prefix}btn-hover-bg);
			border-color: var(--#{$prefix}btn-hover-border-color);
		}

		// Focus state
		&:focus {
			color: var(--#{$prefix}btn-hover-color);
			@include gradient-bg(var(--#{$prefix}btn-hover-bg));
			border-color: var(--#{$prefix}btn-hover-border-color);
			outline: 0;
			@if $enable-shadows {
				box-shadow: var(--#{$prefix}btn-box-shadow),
					var(--#{$prefix}btn-focus-box-shadow);
			} @else {
				box-shadow: var(--#{$prefix}btn-focus-box-shadow);
			}
		}

		// Active state
		&:active {
			color: var(--#{$prefix}btn-active-color);
			background-color: var(--#{$prefix}btn-active-bg);
			background-image: if($enable-gradients, none, null);
			border-color: var(--#{$prefix}btn-active-border-color);

			&:focus {
				@if $enable-shadows {
					box-shadow: var(--#{$prefix}btn-active-shadow),
						var(--#{$prefix}btn-focus-box-shadow);
				} @else {
					box-shadow: var(--#{$prefix}btn-focus-box-shadow);
				}
			}
		}

		// Disabled state
		&:disabled {
			pointer-events: none;
			opacity: var(--#{$prefix}btn-disabled-opacity);
			@include box-shadow(none);
		}

		// Default color theme
		@include button-variant($light, $light);

		// Submit button
		&.trumbowyg-modal-submit {
			@include button-variant($primary, $primary);
		}
	}

	// Dialog overlay
	.trumbowyg-overlay {
		position: absolute;
		background-color: $modal-backdrop-bg;
		opacity: $modal-backdrop-opacity;
		height: 100%;
		width: 100%;
		left: 0;
		display: none;
		top: 0;
		z-index: 10;
	}

	//
	// Fullscreen mode
	//

	// Disable scrollbar in body
	body.trumbowyg-body-fullscreen {
		overflow: hidden;
	}

	// Base
	.trumbowyg-fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		z-index: 99999;

		// Remove borders
		&.trumbowyg-box,
		.trumbowyg-editor {
			border: 0;
			@include border-radius(0);
		}

		// Elements height
		.trumbowyg-editor,
		.trumbowyg-textarea {
			height: calc(100% - #{$trumbowyg-toolbar-height}) !important;
			overflow: auto;
		}
		.trumbowyg-overlay {
			height: 100% !important;
		}

		// Active fullscreen button state
		.trumbowyg-button-group .trumbowyg-fullscreen-button svg {
			color: var(--#{$prefix}body-color);
			fill: transparent;
		}
	}

	//
	// Colors
	//

	.trumbowyg-dropdown-foreColor,
	.trumbowyg-dropdown-backColor {
		padding: $dropdown-padding-y;
		width: (12 * 1.25rem); // 12 items per row

		// Hide icons
		svg {
			display: none !important;
		}

		// Button
		button {
			display: block;
			position: relative;
			float: left;
			text-indent: -9999px;
			border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
			padding: 0;
			width: 1.25rem;
			height: 1.25rem;
			@include transition(
				transform ease-in-out var(--#{$prefix}transition-base-timer)
			);

			&:hover,
			&:focus {
				transform: scale(1.25);
				z-index: 10;
			}
		}
	}
}
