/* ------------------------------------------------------------------------------
*
*  # C3 charts
*
*  Styles for C3.js visualization library
*
* ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-c3 {
	// Base
	.c3 {
		--#{$prefix}c3-tooltip-padding-x: #{$tooltip-padding-x};
		--#{$prefix}c3-tooltip-padding-y: #{$tooltip-padding-y};
		@include rfs($tooltip-font-size, --#{$prefix}c3-tooltip-font-size);
		--#{$prefix}c3-tooltip-color: #{$tooltip-color};
		--#{$prefix}c3-tooltip-bg: #{$tooltip-bg};
		--#{$prefix}c3-tooltip-border-radius: #{$tooltip-border-radius};
		--#{$prefix}c3-tooltip-border-color: rgba(var(--#{$prefix}white-rgb), 0.2);

		@include color-scheme(dark) {
			--#{$prefix}c3-tooltip-color: #{$tooltip-darkmode-color};
			--#{$prefix}c3-tooltip-bg: #{$tooltip-darkmode-bg};
			--#{$prefix}c3-tooltip-border-color: rgba(
				var(--#{$prefix}black-rgb),
				0.2
			);
		}

		// Use smaller text
		svg {
			font-size: $chart-text-size;
		}

		// Paths and lines defaults
		path,
		line {
			fill: none;
		}

		// Disable text selection
		text {
			user-select: none;
		}
	}

	//
	// Axis
	//

	// Base
	.c3-axis,
	.c3-axis-y,
	.c3-axis-x {
		line,
		path {
			stroke: $chart-axis-line-color;
		}

		.tick {
			fill: $chart-axis-text-color;
		}
	}

	// Titles
	.c3-axis-y-label,
	.c3-axis-y2-label,
	.c3-axis-x-label,
	.c3-axis-x2-label,
	.c3-ygrid-line text,
	.c3-xgrid-line text {
		fill: $chart-text-color;
	}

	//
	// Components
	//

	// Default shape rendering style
	.c3-legend-item-tile,
	.c3-xgrid-focus,
	.c3-ygrid,
	.c3-event-rect,
	.c3-bars path,
	.c3 path.domain {
		shape-rendering: crispEdges;
	}

	// Grid
	.c3-grid {
		line {
			stroke: $chart-line-color;
		}
	}
	.c3-xgrid,
	.c3-ygrid {
		stroke-dasharray: 3 3;
	}

	// Text on chart
	.c3-text {
		font-weight: $font-weight-semibold;

		&.c3-empty {
			fill: $chart-text-color;
		}
	}

	// Line
	.c3-line {
		stroke-width: 2px;
	}

	// Area
	.c3-area {
		stroke-width: 0;
		opacity: 0.4;
	}

	// Bar
	.c3-bar {
		stroke-width: 0;

		&._expanded_ {
			fill-opacity: 0.75;
		}
	}

	// Arc
	.c3-chart-arc {
		// Slices
		path {
			stroke: var(--#{$prefix}card-bg);
			stroke-width: 2px;
		}

		// text
		text {
			fill: var(--#{$prefix}white);
			font-size: var(--#{$prefix}body-font-size);
		}

		// Gauge
		.c3-gauge-value {
			fill: var(--#{$prefix}body-color);
			font-size: $h1-font-size;
		}
	}
	.c3-chart-arcs-title {
		font-size: $h6-font-size;
		fill: var(--#{$prefix}body-color);
	}
	.c3-chart-arcs {
		.c3-chart-arcs-background {
			fill: rgba(var(--#{$prefix}body-color-rgb), 0.25);
			stroke: none;
		}

		.c3-chart-arcs-gauge-unit {
			fill: var(--#{$prefix}body-color);
			font-size: $h6-font-size;
		}

		.c3-chart-arcs-gauge-max,
		.c3-chart-arcs-gauge-min {
			fill: var(--#{$prefix}body-color);
		}
	}

	// Focus
	.c3-target {
		&.c3-focused {
			opacity: 1;

			path.c3-line,
			path.c3-step {
				stroke-width: 2px;
			}
		}

		&.c3-defocused {
			opacity: 0.3 !important;
		}
	}

	// Region
	.c3-region {
		fill: $c3-region-bg;
	}

	// Brush
	.c3-brush .extent {
		fill-opacity: 0.1;
	}

	// Legend
	.c3-legend-item {
		font-size: $chart-text-size;

		text {
			fill: $chart-text-color;
		}
	}

	// Circle
	.c3-circle {
		fill: currentColor;
	}

	// Tooltip
	.c3-tooltip {
		border-collapse: collapse;
		border-spacing: 0;
		background-color: var(--#{$prefix}c3-tooltip-bg);
		color: var(--#{$prefix}c3-tooltip-color);
		empty-cells: show;
		@include border-radius(var(--#{$prefix}c3-tooltip-border-radius));

		th {
			padding: var(--#{$prefix}c3-tooltip-padding-y)
				var(--#{$prefix}c3-tooltip-padding-x);
			font-weight: $font-weight-semibold;
			@include border-top-radius(var(--#{$prefix}c3-tooltip-border-radius));
		}

		td {
			padding: var(--#{$prefix}c3-tooltip-padding-y)
				var(--#{$prefix}c3-tooltip-padding-x);
			border-top: var(--#{$prefix}border-width)
				solid
				var(--#{$prefix}c3-tooltip-border-color);
			white-space: nowrap;

			> span {
				display: inline-block;
				margin-right: var(--#{$prefix}spacer-2);
				padding: var(--#{$prefix}spacer-1);
			}

			&.value {
				text-align: right;
			}
		}
	}
}
