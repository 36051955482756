/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap v5.1.0 (https://getbootstrap.com)
 *
 *  Copyright 2011-2018 The Bootstrap Authors
 *  Copyright 2011-2018 Twitter, Inc.
 *  Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 *
 * ---------------------------------------------------------------------------- */

// Import custom template config
@import '../../../config';
@import '../../../utils/ll-functions';
@import '../../../utils/ll-mixins';

// Configuration
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Import template's variables
@import '../variables/variables-core';
@import '../variables/variables-custom';
@import '../../../bootstrap_limitless/maps';
@import '../../../bootstrap_limitless/mixins';
@import '../../../bootstrap_limitless/utilities';

// Layout & components
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import '../../../_bootstrap/tables';
@import '../../../_bootstrap/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/breadcrumb';
@import '../../../_bootstrap/pagination';
@import 'bootstrap/scss/badge';
@import '../../../_bootstrap/alert';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas';
@import 'bootstrap/scss/placeholders';

// Helpers
@import 'bootstrap/scss/helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';

//custom
@import "./custom/sizing";
