/* ------------------------------------------------------------------------------
*
*  # Leaflet maps
*
*  Styles for Leafletjs maps
*
* ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-leaflet-maps {
	// Custom container for leaflet maps that handles dark theme
	.map-leaflet {
		--map-tiles-filter: brightness(0.6)
			invert(1)
			contrast(3)
			hue-rotate(200deg)
			saturate(0.3)
			brightness(0.7);

		@include color-scheme(dark) {
			filter: var(--map-tiles-filter, none);
		}
	}

	// Common styles
	.leaflet-pane,
	.leaflet-tile,
	.leaflet-marker-icon,
	.leaflet-marker-shadow,
	.leaflet-tile-container,
	.leaflet-pane > svg,
	.leaflet-pane > canvas,
	.leaflet-zoom-box,
	.leaflet-image-layer,
	.leaflet-layer {
		position: absolute;
		left: 0;
		top: 0;
	}

	// Base container
	.leaflet-container {
		font-size: var(--#{$prefix}body-font-size-sm);
		overflow: hidden;
		-webkit-tap-highlight-color: transparent;

		// Resets
		.leaflet-marker-pane img,
		.leaflet-shadow-pane img,
		.leaflet-tile-pane img,
		img.leaflet-image-layer,
		.leaflet-tile {
			max-width: none !important;
			max-height: none !important;
			width: auto;
			padding: 0;
		}

		// Interactions
		&.leaflet-touch-zoom {
			touch-action: pan-x pan-y;
		}
		&.leaflet-touch-drag {
			touch-action: none;
			touch-action: pinch-zoom;

			&.leaflet-touch-zoom {
				touch-action: none;
			}
		}
	}

	// User interactions
	.leaflet-tile,
	.leaflet-marker-icon,
	.leaflet-marker-shadow {
		user-select: none;
		-webkit-user-drag: none;
	}

	// Safari specific hacks
	.leaflet-safari {
		// Safari renders non-retina tile on retina better with this, but Chrome is worse
		.leaflet-tile {
			image-rendering: -webkit-optimize-contrast;
		}

		// Hack that prevents hw layers "stretching" when loading new tiles
		.leaflet-tile-container {
			width: 1600px;
			height: 1600px;
			-webkit-transform-origin: 0 0;
		}
	}

	// Markers base
	.leaflet-marker-icon,
	.leaflet-marker-shadow {
		display: block;
	}

	//
	// Tiles
	//

	.leaflet-tile {
		filter: inherit;
		visibility: hidden;
	}
	.leaflet-tile-loaded {
		visibility: inherit;
	}
	.leaflet-zoom-box {
		border: var(--#{$prefix}border-width) dotted var(--#{$prefix}border-color);
		background-color: var(--#{$prefix}gray-100);
		width: 0;
		height: 0;
		z-index: 800;
	}

	//
	// Panes
	//

	.leaflet-pane {
		z-index: 400;

		canvas {
			z-index: 100;
		}
		svg {
			z-index: 200;
		}
	}
	.leaflet-tile-pane {
		z-index: 200;
	}
	.leaflet-overlay-pane {
		z-index: 400;

		svg {
			max-width: none !important;
			max-height: none !important;
			-moz-user-select: none;
		}
	}
	.leaflet-shadow-pane {
		z-index: 500;
	}
	.leaflet-marker-pane {
		z-index: 600;
	}
	.leaflet-tooltip-pane {
		z-index: 650;
	}
	.leaflet-popup-pane {
		z-index: 700;
	}

	//
	// Control positioning
	//

	.leaflet-control {
		position: relative;
		z-index: 800;
		pointer-events: auto;
		float: left;
		clear: both;
	}
	.leaflet-top,
	.leaflet-bottom {
		position: absolute;
		z-index: 1000;
		pointer-events: none;
	}
	.leaflet-top {
		top: 0;

		.leaflet-control {
			margin-top: var(--#{$prefix}spacer-2);
		}
	}
	.leaflet-right {
		right: 0;

		.leaflet-control {
			float: right;
			margin-right: var(--#{$prefix}spacer-2);
		}
	}
	.leaflet-bottom {
		bottom: 0;

		.leaflet-control {
			margin-bottom: var(--#{$prefix}spacer-2);
		}
	}
	.leaflet-left {
		left: 0;

		.leaflet-control {
			margin-left: var(--#{$prefix}spacer-2);
		}
	}

	//
	// Zoom and fade animations
	//

	// Fade
	.leaflet-fade-anim {
		.leaflet-popup {
			opacity: 0;
			transition: opacity var(--#{$prefix}transition-base-timer) linear;
		}
		.leaflet-map-pane .leaflet-popup {
			opacity: 1;
		}
	}

	// Zoom
	.leaflet-zoom-animated {
		transform-origin: 0 0;
	}
	svg.leaflet-zoom-animated {
		will-change: transform;
	}
	.leaflet-zoom-anim .leaflet-zoom-animated {
		transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
	}
	.leaflet-zoom-anim .leaflet-tile,
	.leaflet-pan-anim .leaflet-tile {
		transition: none;
	}
	.leaflet-zoom-anim .leaflet-zoom-hide {
		visibility: hidden;
	}

	//
	// Cursors
	//

	.leaflet-interactive {
		cursor: pointer;
	}
	.leaflet-grab {
		cursor: grab;
	}
	.leaflet-crosshair,
	.leaflet-crosshair .leaflet-interactive {
		cursor: crosshair;
	}
	.leaflet-popup-pane,
	.leaflet-control {
		cursor: auto;
	}
	.leaflet-dragging {
		.leaflet-grab,
		.leaflet-grab .leaflet-interactive,
		.leaflet-marker-draggable {
			cursor: move;
		}
	}

	//
	// Marker & overlays interactivity
	//

	.leaflet-marker-icon,
	.leaflet-marker-shadow,
	.leaflet-image-layer,
	.leaflet-pane > svg path,
	.leaflet-tile-container {
		pointer-events: none;
	}

	.leaflet-marker-icon.leaflet-interactive,
	.leaflet-image-layer.leaflet-interactive,
	.leaflet-pane > svg path.leaflet-interactive,
	svg.leaflet-image-layer.leaflet-interactive path {
		pointer-events: auto;
	}

	//
	// General toolbar styles
	//

	.leaflet-bar {
		border-radius: var(--#{$prefix}border-radius);

		a {
			background-color: var(--#{$prefix}gray-200);
			border-bottom: var(--#{$prefix}border-width)
				solid
				var(--#{$prefix}border-color);
			width: 26px;
			height: 26px;
			line-height: 26px;
			display: block;
			text-align: center;
			color: var(--#{$prefix}body-color);
			@include transition(
				background-color ease-in-out var(--#{$prefix}transition-base-timer)
			);

			// Hover state
			&:hover,
			&:focus {
				background-color: var(--#{$prefix}gray-300);
			}

			// Disabled state
			&.leaflet-disabled {
				cursor: default;
				background-color: var(--#{$prefix}gray-100);
				color: rgba(var(--#{$prefix}body-color-rgb), 0.5);
			}

			// Rounded corners
			&:first-child {
				@include border-top-radius(var(--#{$prefix}border-radius));
			}
			&:last-child {
				border-bottom: 0;
				@include border-bottom-radius(var(--#{$prefix}border-radius));
			}
		}
	}

	// Touch enabled buttons
	.leaflet-touch {
		.leaflet-bar {
			a {
				width: 2rem;
				height: 2rem;
			}
		}

		// Zoom and Reset control
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out,
    .leaflet-control-reset {
      font-size: $h3-font-size;
    }

    .leaflet-control-zoom-out {
      border: 2px solid #0D6A92;
      border-radius: 0 0 5px 5px;
    }

    .leaflet-control-reset {
      margin-top: 2px;
      border: 2px solid #0D6A92;
      border-radius: 5px;
    }

    .leaflet-control-reset:hover {
      border-color: #3e9536;
    }

    .leaflet-control-zoom-in span,
    .leaflet-control-zoom-out span,
    .leaflet-control-reset span {
      display: inline-block;
      position: relative;
      top: 1px;
    }

  }

	//
	// Layers control
	//

	.leaflet-control-layers {
		background-color: var(--#{$prefix}gray-200);
		@include border-radius(var(--#{$prefix}border-radius));
	}
	.leaflet-control-layers-toggle {
		background-image: url(#{$img-path}/vendor/leaflet/layers.png);
		width: 36px;
		height: 36px;

		.leaflet-retina & {
			background-image: url(#{$img-path}/vendor/leaflet/layers-2x.png);
			background-size: 26px 26px;
		}

		.leaflet-touch & {
			width: 44px;
			height: 44px;
		}

		label {
			display: block;
		}
	}
	.leaflet-control-layers .leaflet-control-layers-list,
	.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
		display: none;
	}
	.leaflet-control-layers-expanded {
		padding: var(--#{$prefix}spacer-1)
			var(--#{$prefix}spacer-2)
			var(--#{$prefix}spacer-1)
			var(--#{$prefix}spacer-1);
		color: var(--#{$prefix}body-color);
		background: var(--#{$prefix}gray-200);

		.leaflet-control-layers-list {
			display: block;
			position: relative;
		}
	}
	.leaflet-control-layers-scrollbar {
		overflow-y: scroll;
		overflow-x: hidden;
		padding-right: var(--#{$prefix}spacer-1);
	}
	.leaflet-control-layers-selector {
		margin-top: 2px;
		position: relative;
		top: 1px;
	}
	.leaflet-control-layers-separator {
		height: 0;
		border-top: var(--#{$prefix}border-width)
			solid
			var(--#{$prefix}border-color);
		margin: var(--#{$prefix}spacer-1)
			calc(var(--#{$prefix}spacer-2) * -1)
			var(--#{$prefix}spacer-1)
			calc(var(--#{$prefix}spacer-1) * -1);
	}

	//
	// Icons
	//

	// Default icon URLs
	.leaflet-default-icon-path {
		background-image: url(#{$img-path}/vendor/leaflet/marker-icon.png);
	}

	// div icon
	.leaflet-div-icon {
		background-color: var(--#{$prefix}gray-100);
		border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
	}

	//
	// Attribution and scale controls
	//

	.leaflet-control-attribution {
		background: var(--#{$prefix}gray-100);
		margin: 0;

		svg {
			display: inline !important;
		}
	}
	.leaflet-control-attribution,
	.leaflet-control-scale-line {
		padding: 0 var(--#{$prefix}spacer-1);
		color: var(--#{$prefix}body-color);
	}
	.leaflet-left .leaflet-control-scale {
		margin-left: var(--#{$prefix}spacer-1);
	}
	.leaflet-bottom .leaflet-control-scale {
		margin-bottom: var(--#{$prefix}spacer-1);
	}
	.leaflet-control-scale-line {
		border: var(--#{$prefix}border-width) solid var(--#{$prefix}gray-600);
		border-top: none;
		line-height: 1.1;
		padding: var(--#{$prefix}spacer-1);
		white-space: nowrap;
		overflow: hidden;
		background-color: var(--#{$prefix}gray-100);

		&:not(:first-child) {
			border-top: var(--#{$prefix}border-width) solid var(--#{$prefix}gray-600);
			border-bottom: none;
			margin-top: calc(var(--#{$prefix}border-width) * -1);
		}
		&:not(:first-child):not(:last-child) {
			border-bottom: var(--#{$prefix}border-width)
				solid
				var(--#{$prefix}gray-600);
		}
	}
	.leaflet-touch {
		.leaflet-control-attribution,
		.leaflet-control-layers,
		.leaflet-bar {
			box-shadow: none;
		}
		.leaflet-control-layers,
		.leaflet-bar {
			border: var(--#{$prefix}border-width) solid var(--#{$prefix}gray-600);
			background-clip: padding-box;
		}
	}

	//
	// Popup
	//

	.leaflet-popup {
		position: absolute;
		text-align: center;
		margin-bottom: var(--#{$prefix}spacer);
	}
	.leaflet-popup-content-wrapper {
		text-align: left;
		@include border-radius(var(--#{$prefix}border-radius));
	}
	.leaflet-popup-content {
		padding: var(--#{$prefix}spacer-2) var(--#{$prefix}spacer);
		min-height: 1px;

		p {
			margin-bottom: var(--#{$prefix}spacer);
		}
	}
	.leaflet-popup-tip-container {
		width: calc(var(--#{$prefix}spacer) * 2);
		height: var(--#{$prefix}spacer);
		position: absolute;
		left: 50%;
		margin-top: -1px;
		margin-left: calc(var(--#{$prefix}spacer) * -1);
		overflow: hidden;
		pointer-events: none;
	}
	.leaflet-popup-tip {
		width: var(--#{$prefix}spacer);
		height: var(--#{$prefix}spacer);
		padding: 1px;
		margin: calc(var(--#{$prefix}spacer-2) * -1) auto 0;
		pointer-events: auto;
		transform: rotate(45deg);
	}
	.leaflet-popup-content-wrapper,
	.leaflet-popup-tip {
		background: var(--#{$prefix}gray-100);
		color: var(--#{$prefix}body-color);
		@include box-shadow(var(--#{$prefix}box-shadow));
	}
	.leaflet-container a.leaflet-popup-close-button {
		position: absolute;
		top: 0;
		right: 0;
		border: none;
		text-align: center;
		padding: var(--#{$prefix}spacer-2);
		color: var(--#{$prefix}gray-700);
		text-decoration: none;
		background: transparent;

		&:hover,
		&:focus {
			color: var(--#{$prefix}body-color);
		}
	}
	.leaflet-popup-scrolled {
		overflow: auto;
		border-bottom: var(--#{$prefix}border-width)
			solid
			var(--#{$prefix}body-color);
		border-top: var(--#{$prefix}border-width) solid var(--#{$prefix}body-color);
	}

	//
	// Tooltip
	// Base styles for the element that has a tooltip
	//

	.leaflet-tooltip {
		--#{$prefix}leaflet-tooltip-padding-y: #{$tooltip-padding-y};
		--#{$prefix}leaflet-tooltip-padding-x: #{$tooltip-padding-x};
		--#{$prefix}leaflet-tooltip-bg: #{$tooltip-bg};
		--#{$prefix}leaflet-tooltip-color: #{$tooltip-color};

		@include color-scheme(dark) {
			--#{$prefix}leaflet-tooltip-bg: #{$tooltip-darkmode-bg};
			--#{$prefix}leaflet-tooltip-color: #{$tooltip-darkmode-color};
		}

		position: absolute;
		padding: var(--#{$prefix}leaflet-tooltip-padding-y)
			var(--#{$prefix}leaflet-tooltip-padding-x);
		background-color: var(--#{$prefix}leaflet-tooltip-bg);
		color: var(--#{$prefix}leaflet-tooltip-color);
		white-space: nowrap;
		user-select: none;
		pointer-events: none;
		@include border-radius(var(--#{$prefix}border-radius));
		@include box-shadow(var(--#{$prefix}box-shadow));
	}
	.leaflet-tooltip.leaflet-interactive {
		cursor: pointer;
		pointer-events: auto;
	}

	//
	// Printing
	//

	@media print {
		.leaflet-control {
			-webkit-print-color-adjust: exact;
			print-color-adjust: exact;
		}
	}

	//
	// Cluster
	//

	// Animation
	.leaflet-cluster-anim {
		.leaflet-marker-icon,
		.leaflet-marker-shadow {
			transition:
				transform var(--#{$prefix}transition-collapse-timer) ease-out,
				opacity var(--#{$prefix}transition-collapse-timer) ease-in;
		}
	}
	.leaflet-cluster-spider-leg {
		transition:
			stroke-dashoffset var(--#{$prefix}transition-collapse-timer) ease-out,
			stroke-opacity var(--#{$prefix}transition-collapse-timer) ease-in;
	}

	// Colors and sizes
	.marker-cluster-small {
		background-color: rgba(181, 226, 140, 0.6);

		div {
			background-color: rgba(110, 204, 57, 0.6);
		}
	}
	.marker-cluster-medium {
		background-color: rgba(241, 211, 87, 0.6);

		div {
			background-color: rgba(240, 194, 12, 0.6);
		}
	}
	.marker-cluster-large {
		background-color: rgba(253, 156, 115, 0.6);

		div {
			background-color: rgba(241, 128, 23, 0.6);
		}
	}

	// Container
	.marker-cluster {
		background-clip: padding-box;
		@include border-radius(var(--#{$prefix}border-radius-pill));

		div {
			width: 30px;
			height: 30px;
			margin-left: var(--#{$prefix}spacer-1);
			margin-top: var(--#{$prefix}spacer-1);
			text-align: center;
			font-size: var(--#{$prefix}body-font-size-sm);
			@include border-radius(var(--#{$prefix}border-radius-pill));
		}
		span {
			line-height: 30px;
		}
	}
}
