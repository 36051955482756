/* ------------------------------------------------------------------------------
*
*  # Floating action buttons
*
*  Styles for fab.min.js - material design floating action button with menu
*
* ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-fab {
	// Base
	.fab-menu {
		--#{$prefix}fab-main-btn-size: #{$fab-main-btn-size};
		--#{$prefix}fab-inner-btn-spacing: #{$fab-inner-btn-spacing};
		--#{$prefix}fab-inner-btn-size: #{$fab-inner-btn-size};

		position: relative;
		display: inline-block;
		z-index: $zindex-dropdown - 3;
	}

	//
	// Positions
	//

	// Absolute
	.fab-menu-absolute {
		position: absolute;
	}

	// Fixed
	.fab-menu-fixed {
		position: fixed;
		z-index: $zindex-dropdown - 2;
	}

	//
	// Button placements
	//

	// Top placement
	.fab-menu-top {
		// Top spacing
		.content-wrapper > & {
			top: calc(var(--#{$prefix}fab-main-btn-size) * -1);
		}

		// Inside navbar
		.navbar & {
			bottom: 0;
			transform: translate(50%, calc(50% + var(--#{$prefix}navbar-padding-y)));
		}
	}

	// Bottom placement
	.fab-menu-bottom {
		bottom: 0;
		transition: bottom ease-in-out var(--#{$prefix}transition-base-timer);

		// Keep space for footer
		&.reached-bottom {
			bottom: calc(#{$nav-link-height} + calc(var(--#{$prefix}spacer) * 2));
		}

		// Inside navbar
		.navbar & {
			top: 0;
			transform: translate(calc(50% - var(--#{$prefix}navbar-padding-y)), 50%);
		}
	}

	//
	// Inner menu
	//

	.fab-menu-inner {
		list-style: none;
		margin: 0;
		padding: 0;
		position: absolute;
		left: 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
	}

	//
	// Main button
	//

	// Base
	.fab-menu-btn {
		padding: var(--#{$prefix}fab-main-btn-size);
		z-index: ($zindex-dropdown - 1);

		// Icons
		i {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: calc(calc(var(--#{$prefix}icon-font-size) * 0.5) * -1);
			margin-left: calc(calc(var(--#{$prefix}icon-font-size) * 0.5) * -1);
		}
	}

	// Animation
	.fab-icon-close,
	.fab-icon-open {
		transform: rotate(0deg);
		transition:
			transform ease-in-out calc(var(--#{$prefix}transition-base-timer) * 2),
			opacity ease-in-out calc(var(--#{$prefix}transition-base-timer) * 2);

		// Reverse rotation if active
		.fab-menu[data-fab-toggle='hover']:hover &,
		.fab-menu[data-fab-state='open'] & {
			transform: rotate(180deg);
		}
	}

	// Open icon
	.fab-icon-open {
		.fab-menu[data-fab-toggle='hover']:hover &,
		.fab-menu[data-fab-state='open'] & {
			opacity: 0;
		}
	}

	// Close icon
	.fab-icon-close {
		opacity: 0;

		// Display if active
		.fab-menu[data-fab-toggle='hover']:hover &,
		.fab-menu[data-fab-state='open'] & {
			opacity: 1;
		}
	}

	//
	// Inner menu animation
	//

	// Base
	.fab-menu {
		// Hide items
		.fab-menu-inner > li {
			visibility: hidden;
			opacity: 0;
			transition: all
				ease-in-out
				calc(var(--#{$prefix}transition-base-timer) * 2);
		}

		// Show items
		&[data-fab-toggle='hover']:hover,
		&[data-fab-state='open'] {
			.fab-menu-inner > li {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	// Top position
	.fab-menu-top {
		.fab-menu-inner {
			top: 100%;

			// For proper animation
			> li:not(:first-child) {
				margin-top: -100%;
			}
		}
		&[data-fab-toggle='hover']:hover,
		&[data-fab-state='open'] {
			.fab-menu-inner > li {
				margin-top: var(--fab-inner-btn-spacing);
			}
		}
	}

	// Bottom position
	.fab-menu-bottom {
		.fab-menu-inner {
			bottom: 100%;
			flex-direction: column-reverse;

			// For proper animation
			> li:not(:first-child) {
				margin-bottom: -100%;
			}
		}
		&[data-fab-toggle='hover']:hover,
		&[data-fab-state='open'] {
			.fab-menu-inner > li {
				margin-bottom: var(--fab-inner-btn-spacing);
			}
		}
	}

	//
	// Item labels
	//

	.fab-menu-inner div[data-fab-label] {
		--#{$prefix}fab-tooltip-padding-y: #{$tooltip-padding-y};
		--#{$prefix}fab-tooltip-padding-x: #{$tooltip-padding-x};
		--#{$prefix}fab-tooltip-bg: #{$tooltip-bg};
		--#{$prefix}fab-tooltip-color: #{$tooltip-color};
		--#{$prefix}fab-tooltip-shadow: var(--#{$prefix}box-shadow);

		// Dark theme
		@include color-scheme(dark) {
			--#{$prefix}fab-tooltip-color: #{$tooltip-darkmode-color};
			--#{$prefix}fab-tooltip-bg: #{$tooltip-darkmode-bg};
		}

		position: relative;

		// Base
		&:after {
			content: attr(data-fab-label);
			position: absolute;
			top: 50%;
			right: calc(100% + var(--#{$prefix}spacer-2));
			transform: translateY(-50%);
			color: var(--#{$prefix}fab-tooltip-color);
			background-color: var(--#{$prefix}fab-tooltip-bg);
			padding: var(--#{$prefix}fab-tooltip-padding-y)
				var(--#{$prefix}fab-tooltip-padding-x);
			visibility: hidden;
			opacity: 0;
			white-space: nowrap;
			box-shadow: var(--#{$prefix}fab-tooltip-shadow);
			transition: all
				ease-in-out
				calc(var(--#{$prefix}transition-base-timer) * 2);
			@include border-radius(var(--#{$prefix}border-radius));

			// Reposition them in bottom position
			.fab-menu-bottom & {
				margin-top: calc(
					calc(
							var(--#{$prefix}fab-tooltip-padding-y) +
								var(--#{$prefix}fab-inner-btn-spacing)
						) *
						-1
				);
			}
		}

		// Placement
		&.fab-label-end:after {
			right: auto;
			left: calc(100% + var(--#{$prefix}spacer-2));
		}

		// Display labels
		.fab-menu[data-fab-toggle='hover'] &:hover:after,
		.fab-menu[data-fab-state='open'] &:hover:after {
			visibility: visible;
			opacity: 1;
		}

		// Light label
		&.fab-label-light {
			--#{$prefix}fab-tooltip-bg: var(--#{$prefix}white);
			--#{$prefix}fab-tooltip-color: var(--#{$prefix}black);
			@include color-scheme(dark) {
				--#{$prefix}fab-tooltip-bg: var(--#{$prefix}black);
				--#{$prefix}fab-tooltip-color: var(--#{$prefix}white);
			}
		}

		// Always visible
		&.fab-label-visible:after {
			visibility: visible;
			opacity: 1;
		}
	}
}
