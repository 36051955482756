@mixin rtl {
	@at-root [dir='rtl'] #{&} {
		@content;
	}
}

$ng-select-highlight: $teal !default;
$ng-select-primary-text: #333 !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: #ced4da !default;
$ng-select-border-radius: 0.25rem !default;
$ng-select-bg: #ffffff !default;
$ng-select-selected: lighten($ng-select-highlight, 46) !default;
$ng-select-selected-text: $ng-select-primary-text !default;

$ng-select-marked: lighten($ng-select-highlight, 48) !default;
$ng-select-marked-text: $ng-select-primary-text !default;

$ng-select-box-shadow: $input-focus-box-shadow !default;
$ng-select-placeholder: lighten($ng-select-primary-text, 40) !default;
$ng-select-height: calc(1.5em + 0.75rem + 2px) !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;
$ng-select-value-text: $ng-select-primary-text !default;

$ng-select-dropdown-optgroup-text: rgba(0, 0, 0, 0.54) !default;
$ng-select-dropdown-option-text: rgba(0, 0, 0, 0.87) !default;
$ng-select-dropdown-option-disabled: lighten(
	$ng-select-primary-text,
	60
) !default;

$ng-select-input-text: #000000 !default;
.ng-select {
	--#{$prefix}ng-select-color: #{$form-select-color};
	--#{$prefix}ng-select-bg: #{$form-select-bg};
	--#{$prefix}ng-select-border-color: #{$form-select-border-color};
	--#{$prefix}ng-select-menu-bg: #{$dropdown-bg};
	--#{$prefix}ng-select-menu-border-color: #{$dropdown-border-color};
	--#{$prefix}ng-select-disabled-bg: #{$input-disabled-bg};

	@include color-scheme(dark) {
		--#{$prefix}ng-select-bg: #{$form-select-darkmode-bg};
		--#{$prefix}ng-select-menu-bg: #{$dropdown-darkmode-bg};
		--#{$prefix}ng-select-menu-border-color: #{$dropdown-darkmode-border-color};

		--#{$prefix}ng-select-search-bg: #{$input-darkmode-bg};
		--#{$prefix}ng-select-search-focus-bg: #{$input-darkmode-focus-bg};
	}
	font-family: $input-font-family !important;
	font-size: $input-font-size !important;
	font-weight: $input-font-weight !important;
	line-height: $input-line-height !important;
	&.ng-select-opened {
		&.ng-select-top {
			> .ng-select-container {
				border-top-right-radius: 0;
				border-top-left-radius: 0;
			}
		}
		&.ng-select-right {
			> .ng-select-container {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&.ng-select-bottom {
			> .ng-select-container {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		&.ng-select-left {
			> .ng-select-container {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	&.ng-select-focused {
		&:not(.ng-select-opened) > .ng-select-container {
			border-color: $ng-select-highlight;
			box-shadow: $ng-select-box-shadow;
		}
	}
	&.ng-select-disabled {
		> .ng-select-container {
			background-color: var(--#{$prefix}ng-select-disabled-bg);
		}
	}
	.ng-has-value .ng-placeholder {
		display: none;
	}
	.ng-select-container {
		@extend .form-control;

		color: var(--#{$prefix}ng-select-color);
		align-items: center;
		&:hover {
			box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
		}
		.ng-value-container {
			align-items: center;
			.ng-placeholder {
				color: $input-placeholder-color;
			}
		}
	}
	&.ng-select-multiple {
		&.ng-select-disabled {
			> .ng-select-container .ng-value-container .ng-value {
				background-color: var(--#{$prefix}ng-select-disabled-bg);
				border: 1px solid lighten($ng-select-border, 10);
				.ng-value-label {
					padding: 0 5px;
				}
			}
		}
	}
	.ng-clear-wrapper {
		color: darken($ng-select-border, 20);
		&:hover .ng-clear {
			color: #d0021b;
		}
	}
	.ng-spinner-zone {
		padding: 5px 5px 0 0;

		@include rtl {
			padding: 5px 0 0 5px;
		}
	}
	.ng-arrow-wrapper {
		width: 25px;
		padding-right: 5px;
		@include rtl {
			padding-left: 5px;
			padding-right: 0;
		}
		&:hover {
			.ng-arrow {
				border-top-color: darken($ng-select-border, 40);
			}
		}
		.ng-arrow {
			border-color: darken($ng-select-border, 20) transparent transparent;
			border-style: solid;
			border-width: 5px 5px 2.5px;
		}
	}
}

.ng-dropdown-panel {
	background-color: var(--#{$prefix}ng-select-menu-bg);
	border: 1px solid var(--#{$prefix}ng-select-menu-border-color);
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
	left: 0;
	&.ng-select-top {
		bottom: 100%;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		border-bottom-color: lighten($ng-select-border, 10);
		margin-bottom: -1px;
		.ng-dropdown-panel-items {
			.ng-option {
				&:first-child {
					border-top-right-radius: 4px;
					border-top-left-radius: 4px;
				}
			}
		}
	}
	&.ng-select-right {
		left: 100%;
		top: 0;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		border-bottom-color: lighten($ng-select-border, 10);
		margin-bottom: -1px;
		.ng-dropdown-panel-items {
			.ng-option {
				&:first-child {
					border-top-right-radius: 4px;
				}
			}
		}
	}
	&.ng-select-bottom {
		top: 100%;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		border-top-color: var(--#{$prefix}ng-select-border-color);
		margin-top: -1px;
		.ng-dropdown-panel-items {
			.ng-option {
				&:last-child {
					border-bottom-right-radius: 4px;
					border-bottom-left-radius: 4px;
				}
			}
		}
	}
	&.ng-select-left {
		left: -100%;
		top: 0;
		border-top-left-radius: 4px;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		border-bottom-color: lighten($ng-select-border, 10);
		margin-bottom: -1px;
		.ng-dropdown-panel-items {
			.ng-option {
				&:first-child {
					border-top-left-radius: 4px;
				}
			}
		}
	}
	.ng-dropdown-header {
		border-bottom: 1px solid var(--#{$prefix}ng-select-border-color);
		padding: 5px 7px;
	}
	.ng-dropdown-footer {
		border-top: 1px solid var(--#{$prefix}ng-select-border-color);
		padding: 5px 7px;
	}
	.ng-dropdown-panel-items {
		.ng-optgroup {
			user-select: none;
			padding: 8px 10px;
			font-weight: 500;
			color: $ng-select-dropdown-optgroup-text;
			cursor: pointer;
			&.ng-option-disabled {
				cursor: default;
			}
			&.ng-option-marked {
				background-color: $ng-select-marked;
			}
			&.ng-option-selected,
			&.ng-option-selected.ng-option-marked {
				background-color: $ng-select-selected;
				font-weight: 600;
			}
		}
		.ng-option {
			background-color: var(--#{$prefix}ng-select-bg);
			color: var(--#{$prefix}ng-select-color);
			padding: 8px 10px;
			&.ng-option-selected,
			&.ng-option-selected.ng-option-marked {
				color: $ng-select-selected-text;
				background-color: $ng-select-selected;
				.ng-option-label {
					font-weight: 600;
				}
			}
			&.ng-option-marked {
				background-color: $ng-select-marked;
				color: $ng-select-marked-text;
			}
			&.ng-option-disabled {
				color: $ng-select-dropdown-option-disabled;
			}
			&.ng-option-child {
				padding-left: 22px;
				@include rtl {
					padding-right: 22px;
					padding-left: 0;
				}
			}
			.ng-tag-label {
				font-size: 80%;
				font-weight: 400;
				padding-right: 5px;
				@include rtl {
					padding-left: 5px;
					padding-right: 0;
				}
			}
		}
	}

	@include rtl {
		direction: rtl;
		text-align: right;
	}
}

ng-select.ng-invalid.ng-touched .ng-select-container {
	border-color: $danger;
}
