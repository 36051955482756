@mixin alert-variant($background, $border, $color) {
	--#{$prefix}alert-color: #{$color};
	--#{$prefix}alert-bg: #{$background};
	--#{$prefix}alert-border-color: #{$border};
	--#{$prefix}alert-link-color: #{shade-color($color, 20%)};
	--#{$prefix}alert-link-hover-color: #{shade-color($color, 30%)};

	@if $enable-gradients {
		background-image: var(--#{$prefix}gradient);
	}

	.alert-link {
		color: var(--#{$prefix}alert-link-color);

		&:hover,
		&:focus {
			color: var(--#{$prefix}alert-link-hover-color);
		}
	}
}
