// This mixin uses an `if()` technique to be compatible with Dart Sass
// See https://github.com/sass/sass/issues/1873#issuecomment-152293725 for more details

@mixin form-validation-state(
	$state,
	$color,
	$icon,
	$tooltip-color: color-contrast($color),
	$tooltip-bg-color: rgba($color, $form-feedback-tooltip-opacity),
	$focus-box-shadow: 0 0 $input-btn-focus-blur $input-focus-width
		rgba($color, $input-btn-focus-color-opacity)
) {
	.#{$state}-feedback {
		--#{$prefix}form-validation-color: #{$color};
		display: none;
		width: 100%;
		margin-top: $form-feedback-margin-top;
		@include font-size($form-feedback-font-size);
		font-style: $form-feedback-font-style;
		color: var(--#{$prefix}form-validation-color);
	}

	.#{$state}-tooltip {
		--#{$prefix}form-validation-tooltip-color: #{$tooltip-color};
		--#{$prefix}form-validation-tooltip-bg: #{$tooltip-bg-color};
		--#{$prefix}form-validation-tooltip-border-radius: #{$form-feedback-tooltip-border-radius};
		position: absolute;
		top: 100%;
		z-index: 5;
		display: none;
		max-width: 100%; // Contain to parent when possible
		padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
		margin-top: 0.1rem;
		@include font-size($form-feedback-tooltip-font-size);
		line-height: $form-feedback-tooltip-line-height;
		color: var(--#{$prefix}form-validation-tooltip-color);
		background-color: var(--#{$prefix}form-validation-tooltip-bg);
		@include border-radius(
			var(--#{$prefix}form-validation-tooltip-border-radius)
		);
	}

	@include form-validation-state-selector($state) {
		~ .#{$state}-feedback,
		~ .#{$state}-tooltip {
			display: block;
		}
	}

	.form-control {
		@include form-validation-state-selector($state) {
			--#{$prefix}form-validation-border-color: #{$color};
			--#{$prefix}form-validation-focus-box-shadow: #{$focus-box-shadow};
			border-color: var(--#{$prefix}form-validation-border-color);

			@if $enable-validation-icons {
				padding-right: $input-height-inner;
				background-image: escape-svg($icon);
				background-repeat: no-repeat;
				background-position: right $input-height-inner-quarter center;
				background-size: $input-height-inner-half $input-height-inner-half;
			}

			&:focus {
				box-shadow: var(--#{$prefix}form-validation-focus-box-shadow);
			}
		}
	}

	// stylelint-disable-next-line selector-no-qualifying-type
	textarea.form-control {
		@include form-validation-state-selector($state) {
			@if $enable-validation-icons {
				padding-right: $input-height-inner;
				background-position: top
					$input-height-inner-quarter
					right
					$input-height-inner-quarter;
			}
		}
	}

	.form-select {
		@include form-validation-state-selector($state) {
			--#{$prefix}form-validation-border-color: #{$color};
			--#{$prefix}form-validation-focus-box-shadow: #{$focus-box-shadow};
			border-color: var(--#{$prefix}form-validation-border-color);

			@if $enable-validation-icons {
				&:not([multiple]):not([size]),
				&:not([multiple])[size='1'] {
					padding-right: $form-select-feedback-icon-padding-end;
					background-image: escape-svg($form-select-indicator),
						escape-svg($icon);
					background-position: $form-select-bg-position,
						$form-select-feedback-icon-position;
					background-size: $form-select-bg-size, $form-select-feedback-icon-size;

					[data-color-theme='dark'] & {
						background-image: escape-svg($form-select-indicator-dark),
							escape-svg($icon);
					}
				}
			}

			&:focus {
				border-color: var(--#{$prefix}form-validation-border-color);
				box-shadow: var(--#{$prefix}form-validation-focus-box-shadow);
			}
		}
	}

	.form-control-color {
		@include form-validation-state-selector($state) {
			@if $enable-validation-icons {
				width: add($form-color-width, $input-height-inner);
			}
		}
	}

	.form-check-input {
		@include form-validation-state-selector($state) {
			--#{$prefix}focus-ring-box-shadow: 0 0 #{$input-btn-focus-blur} #{$input-focus-width}
				rgba(#{to-rgb($color)}, #{$input-btn-focus-color-opacity});
			--#{$prefix}component-active-bg: #{$color};
			border-color: var(--#{$prefix}component-active-bg);

			~ .form-check-label {
				--#{$prefix}form-validation-color: #{$color};
				color: var(--#{$prefix}form-validation-color);
			}
		}
	}
	.form-check-inline .form-check-input {
		~ .#{$state}-feedback {
			margin-left: 0.5em;
		}
	}

	.input-group {
		> .form-control:not(:focus),
		> .form-select:not(:focus),
		> .form-floating:not(:focus-within) {
			@include form-validation-state-selector($state) {
				@if $state == 'valid' {
					z-index: 3;
				} @else if $state == 'invalid' {
					z-index: 4;
				}
			}
		}
	}
}
