// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
$white: #fff;
$gray-100: #f9fafb;
$gray-200: #f3f4f6;
$gray-300: #e5e7eb;
$gray-400: #d1d5db;
$gray-500: #9ca3af;
$gray-550: #728187;
$gray-600: #6b7280;
$gray-650: #576469;
$gray-700: #4b5563;
$gray-800: #374151;
$gray-900: #1f2937;
$black: #000;
$white: #fff;

$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '550': $gray-550,
  '600': $gray-600,
  '650': $gray-650,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
);

$blue: #0D6A92;
$photoblue: #ADD8F2;
$frost: #679CBC;
$aero: #76BDE9;
$tertiary: #576469;
$quaternary: #646464;
$indigo: #5c6bc0;
$purple: #8e70c1;
$pink: #f35c86;
$red: #ef4444;
$orange: #f58646;
$yellow: #ffd648;
$green: #059669;
$teal: #0d6a92;
$cyan: #049aad;
$slate: #247297 !default;



// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 2.5;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black;
$color-contrast-light: $white;

// Color palette
$blue-100: tint-color($blue, 90%);
$blue-200: tint-color($blue, 60%);
$blue-300: tint-color($blue, 40%);
$blue-400: tint-color($blue, 20%);
$blue-500: $blue;
$blue-600: shade-color($blue, 20%);
$blue-700: shade-color($blue, 40%);
$blue-800: shade-color($blue, 60%);
$blue-900: shade-color($blue, 90%);

$photoblue-100: tint-color($photoblue, 90%);
$photoblue-200: tint-color($photoblue, 60%);
$photoblue-300: tint-color($photoblue, 40%);
$photoblue-400: tint-color($photoblue, 20%);
$photoblue-500: $photoblue;
$photoblue-600: shade-color($photoblue, 20%);
$photoblue-700: shade-color($photoblue, 40%);
$photoblue-800: shade-color($photoblue, 60%);
$photoblue-900: shade-color($photoblue, 90%);

$frost-100: tint-color($frost, 90%);
$frost-200: tint-color($frost, 60%);
$frost-300: tint-color($frost, 40%);
$frost-400: tint-color($frost, 20%);
$frost-500: $frost;
$frost-600: shade-color($frost, 20%);
$frost-700: shade-color($frost, 40%);
$frost-800: shade-color($frost, 60%);
$frost-900: shade-color($frost, 90%);

$aero-100: tint-color($aero, 90%);
$aero-200: tint-color($aero, 60%);
$aero-300: tint-color($aero, 40%);
$aero-400: tint-color($aero, 20%);
$aero-500: $aero;
$aero-600: shade-color($aero, 20%);
$aero-700: shade-color($aero, 40%);
$aero-800: shade-color($aero, 60%);
$aero-900: shade-color($aero, 90%);

$tertiary-100: tint-color($tertiary, 90%);
$tertiary-200: tint-color($tertiary, 60%);
$tertiary-300: tint-color($tertiary, 40%);
$tertiary-400: tint-color($tertiary, 20%);
$tertiary-500: $tertiary;
$tertiary-600: shade-color($tertiary, 20%);
$tertiary-700: shade-color($tertiary, 40%);
$tertiary-800: shade-color($tertiary, 60%);
$tertiary-900: shade-color($tertiary, 90%);

$quaternary-100: tint-color($quaternary, 90%);
$quaternary-200: tint-color($quaternary, 60%);
$quaternary-300: tint-color($quaternary, 40%);
$quaternary-400: tint-color($quaternary, 20%);
$quaternary-500: $quaternary;
$quaternary-600: shade-color($quaternary, 20%);
$quaternary-700: shade-color($quaternary, 40%);
$quaternary-800: shade-color($quaternary, 60%);
$quaternary-900: shade-color($quaternary, 90%);

$indigo-100: tint-color($indigo, 90%);
$indigo-200: tint-color($indigo, 60%);
$indigo-300: tint-color($indigo, 40%);
$indigo-400: tint-color($indigo, 20%);
$indigo-500: $indigo;
$indigo-600: shade-color($indigo, 20%);
$indigo-700: shade-color($indigo, 40%);
$indigo-800: shade-color($indigo, 60%);
$indigo-900: shade-color($indigo, 90%);

$purple-100: tint-color($purple, 90%);
$purple-200: tint-color($purple, 60%);
$purple-300: tint-color($purple, 40%);
$purple-400: tint-color($purple, 20%);
$purple-500: $purple;
$purple-600: shade-color($purple, 20%);
$purple-700: shade-color($purple, 40%);
$purple-800: shade-color($purple, 60%);
$purple-900: shade-color($purple, 90%);

$pink-100: tint-color($pink, 90%);
$pink-200: tint-color($pink, 60%);
$pink-300: tint-color($pink, 40%);
$pink-400: tint-color($pink, 20%);
$pink-500: $pink;
$pink-600: shade-color($pink, 20%);
$pink-700: shade-color($pink, 40%);
$pink-800: shade-color($pink, 60%);
$pink-900: shade-color($pink, 90%);

$red-100: tint-color($red, 90%);
$red-200: tint-color($red, 60%);
$red-300: tint-color($red, 40%);
$red-400: tint-color($red, 20%);
$red-500: $red;
$red-600: shade-color($red, 20%);
$red-700: shade-color($red, 40%);
$red-800: shade-color($red, 60%);
$red-900: shade-color($red, 90%);

$orange-100: tint-color($orange, 90%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 90%);

$yellow-100: tint-color($yellow, 90%);
$yellow-200: tint-color($yellow, 60%);
$yellow-300: tint-color($yellow, 40%);
$yellow-400: tint-color($yellow, 20%);
$yellow-500: $yellow;
$yellow-600: shade-color($yellow, 20%);
$yellow-700: shade-color($yellow, 40%);
$yellow-800: shade-color($yellow, 60%);
$yellow-900: shade-color($yellow, 90%);

$green-100: tint-color($green, 90%);
$green-200: tint-color($green, 60%);
$green-300: tint-color($green, 40%);
$green-400: tint-color($green, 20%);
$green-500: $green;
$green-600: shade-color($green, 20%);
$green-700: shade-color($green, 40%);
$green-800: shade-color($green, 60%);
$green-900: shade-color($green, 90%);

$teal-100: tint-color($teal, 90%);
$teal-200: tint-color($teal, 60%);
$teal-300: tint-color($teal, 40%);
$teal-400: tint-color($teal, 20%);
$teal-500: $teal;
$teal-600: shade-color($teal, 20%);
$teal-700: shade-color($teal, 40%);
$teal-800: shade-color($teal, 60%);
$teal-900: shade-color($teal, 90%);

$cyan-100: tint-color($cyan, 90%);
$cyan-200: tint-color($cyan, 60%);
$cyan-300: tint-color($cyan, 40%);
$cyan-400: tint-color($cyan, 20%);
$cyan-500: $cyan;
$cyan-600: shade-color($cyan, 20%);
$cyan-700: shade-color($cyan, 40%);
$cyan-800: shade-color($cyan, 60%);
$cyan-900: shade-color($cyan, 90%);

$slate-100: tint-color($slate, 90%) !default;
$slate-200: tint-color($slate, 60%) !default;
$slate-300: tint-color($slate, 40%) !default;
$slate-400: tint-color($slate, 20%) !default;
$slate-500: $slate !default;
$slate-600: shade-color($slate, 20%) !default;
$slate-700: shade-color($slate, 40%) !default;
$slate-800: shade-color($slate, 60%) !default;
$slate-900: shade-color($slate, 80%) !default;

// Color palette maps

$blues: (
  'blue-100': $blue-100,
  'blue-200': $blue-200,
  'blue-300': $blue-300,
  'blue-400': $blue-400,
  'blue-500': $blue-500,
  'blue-600': $blue-600,
  'blue-700': $blue-700,
  'blue-800': $blue-800,
  'blue-900': $blue-900,
);

$photoblues: (
  'photoblue-100': $photoblue-100,
  'photoblue-200': $photoblue-200,
  'photoblue-300': $photoblue-300,
  'photoblue-400': $photoblue-400,
  'photoblue-500': $photoblue-500,
  'photoblue-600': $photoblue-600,
  'photoblue-700': $photoblue-700,
  'photoblue-800': $photoblue-800,
  'photoblue-900': $photoblue-900,
);

$frosts: (
  'frost-100': $frost-100,
  'frost-200': $frost-200,
  'frost-300': $frost-300,
  'frost-400': $frost-400,
  'frost-500': $frost-500,
  'frost-600': $frost-600,
  'frost-700': $frost-700,
  'frost-800': $frost-800,
  'frost-900': $frost-900,
);

$aeros: (
  'aero-100': $aero-100,
  'aero-200': $aero-200,
  'aero-300': $aero-300,
  'aero-400': $aero-400,
  'aero-500': $aero-500,
  'aero-600': $aero-600,
  'aero-700': $aero-700,
  'aero-800': $aero-800,
  'aero-900': $aero-900,
);

$tertiarys: (
  'tertiary-100': $tertiary-100,
  'tertiary-200': $tertiary-200,
  'tertiary-300': $tertiary-300,
  'tertiary-400': $tertiary-400,
  'tertiary-500': $tertiary-500,
  'tertiary-600': $tertiary-600,
  'tertiary-700': $tertiary-700,
  'tertiary-800': $tertiary-800,
  'tertiary-900': $tertiary-900,
);

$quaternarys: (
  'quaternary-100': $quaternary-100,
  'quaternary-200': $quaternary-200,
  'quaternary-300': $quaternary-300,
  'quaternary-400': $quaternary-400,
  'quaternary-500': $quaternary-500,
  'quaternary-600': $quaternary-600,
  'quaternary-700': $quaternary-700,
  'quaternary-800': $quaternary-800,
  'quaternary-900': $quaternary-900,
);

$indigos: (
  'indigo-100': $indigo-100,
  'indigo-200': $indigo-200,
  'indigo-300': $indigo-300,
  'indigo-400': $indigo-400,
  'indigo-500': $indigo-500,
  'indigo-600': $indigo-600,
  'indigo-700': $indigo-700,
  'indigo-800': $indigo-800,
  'indigo-900': $indigo-900,
);

$purples: (
  'purple-100': $purple-100,
  'purple-200': $purple-200,
  'purple-300': $purple-300,
  'purple-400': $purple-400,
  'purple-500': $purple-500,
  'purple-600': $purple-600,
  'purple-700': $purple-700,
  'purple-800': $purple-800,
  'purple-900': $purple-900,
);

$pinks: (
  'pink-100': $pink-100,
  'pink-200': $pink-200,
  'pink-300': $pink-300,
  'pink-400': $pink-400,
  'pink-500': $pink-500,
  'pink-600': $pink-600,
  'pink-700': $pink-700,
  'pink-800': $pink-800,
  'pink-900': $pink-900,
);

$reds: (
  'red-100': $red-100,
  'red-200': $red-200,
  'red-300': $red-300,
  'red-400': $red-400,
  'red-500': $red-500,
  'red-600': $red-600,
  'red-700': $red-700,
  'red-800': $red-800,
  'red-900': $red-900,
);

$oranges: (
  'orange-100': $orange-100,
  'orange-200': $orange-200,
  'orange-300': $orange-300,
  'orange-400': $orange-400,
  'orange-500': $orange-500,
  'orange-600': $orange-600,
  'orange-700': $orange-700,
  'orange-800': $orange-800,
  'orange-900': $orange-900,
);

$yellows: (
  'yellow-100': $yellow-100,
  'yellow-200': $yellow-200,
  'yellow-300': $yellow-300,
  'yellow-400': $yellow-400,
  'yellow-500': $yellow-500,
  'yellow-600': $yellow-600,
  'yellow-700': $yellow-700,
  'yellow-800': $yellow-800,
  'yellow-900': $yellow-900,
);

$greens: (
  'green-100': $green-100,
  'green-200': $green-200,
  'green-300': $green-300,
  'green-400': $green-400,
  'green-500': $green-500,
  'green-600': $green-600,
  'green-700': $green-700,
  'green-800': $green-800,
  'green-900': $green-900,
);

$teals: (
  'teal-100': $teal-100,
  'teal-200': $teal-200,
  'teal-300': $teal-300,
  'teal-400': $teal-400,
  'teal-500': $teal-500,
  'teal-600': $teal-600,
  'teal-700': $teal-700,
  'teal-800': $teal-800,
  'teal-900': $teal-900,
);

$cyans: (
  'cyan-100': $cyan-100,
  'cyan-200': $cyan-200,
  'cyan-300': $cyan-300,
  'cyan-400': $cyan-400,
  'cyan-500': $cyan-500,
  'cyan-600': $cyan-600,
  'cyan-700': $cyan-700,
  'cyan-800': $cyan-800,
  'cyan-900': $cyan-900,
);

$slates: (
  'slate-100': $slate-100,
  'slate-200': $slate-200,
  'slate-300': $slate-300,
  'slate-400': $slate-400,
  'slate-500': $slate-500,
  'slate-600': $slate-600,
  'slate-700': $slate-700,
  'slate-800': $slate-800,
  'slate-900': $slate-900,
) !default;

// Contextual colors
$primary: $blue;
$secondary: $photoblue;
$frost: $frost;
$aero: $aero;
$tertiary: $tertiary;
$quaternary: $quaternary;
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red;
$light: $gray-200;
$dark: #252b36;

$primary-light: #9BC1D4;
$primary-light-1: #BFD9E4;
$primary-light-2: #DFECF3;
$tertiary-light: #728187;
$green-dark: #3E9536;
$green-light: #60D954;
$green-light-1: #82D97A;
$green-light-2: #B8ECB5;
$ocean-dark: #6883E3;
$ocean-light: #C9D5FF;
$puple-light: #CCC0FC;
$yellow-dark: #9E8239;
$yellow-light: #F6D889;
$yellow-light-1: #F1D0B2;
$red-dark: #8E3623;
$red-light: #FCB09F;
$cyan-dark: #1884B2;
$cyan-light: #cde5f3;

$estacao-status-1:#3E9536;
$estacao-status-2:#F8C02F;
$estacao-status-3:#ED8936;
$estacao-status-4: #b7b5b5;


// Light theme colors
$theme-colors: (
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'teal': $teal,
  'yellow': $yellow,
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'frost': $frost,
  'aero': $aero,
  'tertiary': $tertiary,
  'quaternary': $quaternary,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'black': $black,
  'white': $white,
  'primary-light':$primary-light,
  'primary-light-1':$primary-light-1,
  'primary-light-2':$primary-light-2,
  'tertiary-light':$tertiary-light,
  'green-dark':$green-dark,
  'green-light':$green-light,
  'green-light-1':$green-light-1,
  'green-light-2':$green-light-2,
  'ocean-dark':$ocean-dark,
  'ocean-light':$ocean-light,
  'puple-light':$puple-light,
  'cyan-light':$cyan-light,
  'yellow-dark':$yellow-dark,
  'yellow-light':$yellow-light,
  'yellow-light-1':$yellow-light-1,
  'red-dark':$red-dark,
  'red-light':$red-light,
  'cyan-dark': $cyan-dark,
  'estacao-status-1':$estacao-status-1,
  'estacao-status-2':$estacao-status-2,
  'estacao-status-3':$estacao-status-3,
  'estacao-status-4':$estacao-status-4,
);

// Dark theme colors
$dark-theme-colors: (
  'indigo': $indigo-300,
  'purple': $purple-300,
  'pink': $pink-300,
  'teal': $teal-300,
  'yellow': $yellow-300,
  'primary': $blue-300,
  'secondary': $photoblue-300,
  'frost': $frost-300,
  'aero': $aero-300,
  'tertiary': $tertiary-300,
  'quaternary': $quaternary-300,
  'success': $green-300,
  'info': $cyan-300,
  'warning': $orange-300,
  'danger': $red-300,
  'light': $gray-500,
  'dark': $gray-100,
  'primary-light':$primary-light,
  'primary-light-1':$primary-light-1,
  'primary-light-2':$primary-light-2,
  'tertiary-light':$tertiary-light,
  'green-dark':$green-dark,
  'green-light':$green-light,
  'green-light-1':$green-light-1,
  'green-light-2':$green-light-2,
  'ocean-dark':$ocean-dark,
  'ocean-light':$ocean-light,
  'puple-light':$puple-light,
  'cyan-light':$cyan-light,
  'yellow-dark':$yellow-dark,
  'yellow-light':$yellow-light,
  'yellow-light-1':$yellow-light-1,
  'red-dark':$red-dark,
  'red-light':$red-light,
  'cyan-dark': $cyan-dark,
  'estacao-status-1':$estacao-status-1,
  'estacao-status-2':$estacao-status-2,
  'estacao-status-3':$estacao-status-3,
  'estacao-status-4':$estacao-status-4,
) !default;
