/* ------------------------------------------------------------------------------
 *
 *  # Row Reorder extension
 *
 *  RowReorder adds the ability for rows in a DataTable to be reordered through
 *  user interaction with the table.
 *
 * ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-datatable-row-reorder {
	// The movable row
	.dt-rowReorder-float {
		position: absolute !important;
		table-layout: static;
		outline: var(--#{$prefix}table-border-width)
			dashed
			var(--#{$prefix}table-border-color);
		outline-offset: calc(var(--#{$prefix}table-border-width) * -1);
		background-color: rgba(var(--#{$prefix}light-rgb), 0.75);
		z-index: $zindex-fixed;
		cursor: move;
	}

	// The target row
	.dt-rowReorder-moving {
		outline: var(--#{$prefix}table-border-width)
			solid
			var(--#{$prefix}table-border-color);
		outline-offset: calc(var(--#{$prefix}table-border-width) * -1);
	}

	// Hide overflow from <body> tag when moving
	.dt-rowReorder-noOverflow {
		overflow-x: hidden;
	}

	// Reorder cell
	.dataTable td.reorder {
		text-align: center;
		cursor: move;
	}
}
