// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-select {
	--#{$prefix}form-select-padding-y: #{$form-select-padding-y};
	--#{$prefix}form-select-padding-x: #{$form-select-padding-x};
	--#{$prefix}form-select-bg: #{$form-select-bg};
	--#{$prefix}form-select-bg-position: #{$form-select-bg-position};
	--#{$prefix}form-select-bg-size: #{$form-select-bg-size};
	--#{$prefix}form-select-color: #{$form-select-color};
	--#{$prefix}form-select-font-family: #{$form-select-font-family};
	@include rfs($form-select-font-size, --#{$prefix}form-select-font-size);
	--#{$prefix}form-select-line-height: #{$form-select-line-height};
	--#{$prefix}form-select-border-width: #{$form-select-border-width};
	--#{$prefix}form-select-border-color: #{$form-select-border-color};
	--#{$prefix}form-select-border-radius: #{$form-select-border-radius};
	--#{$prefix}form-select-box-shadow: #{$form-select-box-shadow};
	--#{$prefix}form-select-focus-border-color: #{$form-select-focus-border-color};
	--#{$prefix}form-select-focus-box-shadow: #{$form-select-focus-box-shadow};
	--#{$prefix}form-select-disabled-color: #{$form-select-disabled-color};
	--#{$prefix}form-select-disabled-bg: #{$form-select-disabled-bg};
	--#{$prefix}form-select-disabled-border-color: #{$form-select-disabled-border-color};
	--#{$prefix}form-select-indicator-padding: #{$form-select-indicator-padding};

	display: block;
	width: 100%;
	padding: var(--#{$prefix}form-select-padding-y)
		var(--#{$prefix}form-select-indicator-padding)
		var(--#{$prefix}form-select-padding-y)
		var(--#{$prefix}form-select-padding-x);
	-moz-padding-start: subtract(
		var(--#{$prefix}form-select-padding-x),
		3px
	); // See https://github.com/twbs/bootstrap/issues/32636
	font-family: var(--#{$prefix}form-select-font-family);
	font-size: var(--#{$prefix}form-select-font-size);
	font-weight: $form-select-font-weight;
	line-height: var(--#{$prefix}form-select-line-height);
	color: var(--#{$prefix}form-select-color);
	background-color: var(--#{$prefix}form-select-bg);
	background-image: escape-svg($form-select-indicator);
	background-repeat: no-repeat;
	background-position: var(--#{$prefix}form-select-bg-position);
	background-size: var(--#{$prefix}form-select-bg-size);
	border: var(--#{$prefix}form-select-border-width) solid
		var(--#{$prefix}form-select-border-color);
	@include border-radius(var(--#{$prefix}form-select-border-radius), 0);
	@include box-shadow(var(--#{$prefix}form-select-box-shadow));
	@include transition($form-select-transition);
	appearance: none;

	&:focus {
		border-color: var(--#{$prefix}form-select-focus-border-color);
		outline: 0;
		@if $enable-shadows {
			@include box-shadow(
				var(--#{$prefix}form-select-box-shadow),
				var(--#{$prefix}form-select-focus-box-shadow)
			);
		} @else {
			// Avoid using mixin so we can pass custom focus shadow properly
			box-shadow: var(--#{$prefix}form-select-focus-box-shadow);
		}
	}

	&[multiple],
	&[size]:not([size='1']) {
		padding-right: var(--#{$prefix}form-select-padding-x);
		background-image: none;
	}

	&:disabled {
		color: var(--#{$prefix}form-select-disabled-color);
		background-color: var(--#{$prefix}form-select-disabled-bg);
		border-color: var(--#{$prefix}form-select-disabled-border-color);
	}

	// Remove outline from select box in FF
	&:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 var(--#{$prefix}form-select-color);
	}
}

.form-select-sm {
	--#{$prefix}form-select-padding-y: #{$form-select-padding-y-sm};
	--#{$prefix}form-select-padding-x: #{$form-select-padding-x-sm};
	@include rfs($form-select-font-size-sm, --#{$prefix}form-select-font-size);
	--#{$prefix}form-select-line-height: #{$form-select-line-height-sm};
	--#{$prefix}form-select-border-radius: #{$form-select-border-radius-sm};
}

.form-select-lg {
	--#{$prefix}form-select-padding-y: #{$form-select-padding-y-lg};
	--#{$prefix}form-select-padding-x: #{$form-select-padding-x-lg};
	@include rfs($form-select-font-size-lg, --#{$prefix}form-select-font-size);
	--#{$prefix}form-select-line-height: #{$form-select-line-height-lg};
	--#{$prefix}form-select-border-radius: #{$form-select-border-radius-lg};
}
