@import 'tema/layouts/layout_6/compile/bootstrap';
@import 'tema/layouts/layout_6/compile/bootstrap_limitless';
@import 'tema/layouts/layout_6/compile/components';
@import 'tema/layouts/layout_6/compile/layout';
@import './assets/scss/phosphor-icons';
@import './assets/css/animate.min.css';
@import './assets/scss/ng-select';
@import './assets/scss/leaflet';
@import '../node_modules/angular-calendar/css/angular-calendar.css';
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css');

* {
	font-style: normal;
	line-height: normal;
}
modal-container {
	background-color: rgba(0, 0, 0, 0.35);
}
bs-modal-backdrop {
	background-color: transparent !important;
}
.disabled {
	opacity: 0.5;
	pointer-events: none;
	cursor: not-allowed;
}
seira-button[ng-reflect-disabled-button='true'] {
	pointer-events: none;
	cursor: not-allowed;
}
.form-label {
	font-size: 16px;
	font-weight: 400;
}

@media (max-width: 1199.98px) {
	.form-label {
		font-size: 12px;
	}
}

.expandir-flex {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

.backdrop {
	z-index: 1 !important;
}

.popover {
	position: absolute;
	top: 0;
	left: 0 #{'/* rtl:ignore */'};
}

.popover-arrow {
	position: absolute;
}

.tooltip {
	position: absolute;
}

.tooltip-arrow {
	position: absolute;
}

.modal.fade {
	transition: opacity 0s linear 0s;
}
.modal.fade.show {
	transition: opacity 0.15s linear 0s;
}
.modal-xxl {
	width: 100%;
	max-width: 1600px;
}
.cursor-pointer {
	cursor: pointer;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
input[type='search']::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

.fw-275 {
	font-weight: 275;
	line-height: normal;
}

.fw-900 {
	font-weight: 900;
}

.fw-semibold-400 {
	font-weight: 400 !important;
}
.fw-semibold {
	font-weight: 500 !important;
}
.fw-semibold-1 {
	font-weight: 600 !important;
}
.fw-semibold-2 {
	font-weight: 400 !important;
}
.fw-semibold-3 {
	font-weight: 300 !important;
}

.display-7 {
	font-size: 1.875rem !important;
}
.font-size-14 {
	font-size: 14px;
}
.font-size-15 {
	font-size: 15px;
}
.font-size-16 {
	font-size: 1rem;
}
.font-size-18 {
	font-size: 1.125rem;
}
.font-size-24 {
	font-size: 24px;
}
.font-size-40 {
	font-size: 2.5rem;
}
.font-size-12 {
	font-size: 0.75rem;
}

.carregamento-inicial {
	max-height: 80px;
	-webkit-animation: animation-initial 2s infinite ease-in-out;
	animation: animation-initial 2s infinite ease-in-out;
}
#app-loading {
	background-color: rgb(241, 244, 249);
	z-index: 5000;
	position: absolute;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

@-webkit-keyframes animation-initial {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

@keyframes animation-initial {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes sk-cubeGridScaleDelay {
	0%,
	70%,
	100% {
		transform: scale3D(1, 1, 1);
	}
	35% {
		transform: scale3D(0, 0, 1);
	}
}

@keyframes sk-cubeGridScaleDelay {
	0%,
	70%,
	100% {
		transform: scale3D(1, 1, 1);
	}
	35% {
		transform: scale3D(0, 0, 1);
	}
}

#monitoramento-mapa {
	.leaflet-control-container div:nth-child(2) {
		width: 100%;
		height: 100%;
		.spinner-border-wrapper {
			width: 100%;
			height: 100%;
			.spinner-border {
				--spinner-width: 4rem;
				--spinner-height: 4rem;
				--spinner-border-width: #{$spinner-border-width-lg};
			}
		}
	}
}

.public-component:hover {
	color: $green-dark !important;
	font-weight: $font-weight-medium !important;
}
.public-component:active {
	color: $green-light !important;
}
.public-component {
	color: $primary !important;
	text-decoration: none !important;
}

.danger.public-component:hover {
	color: $red-dark !important;
}
.danger.public-component:active {
	color: $red-light !important;
}
.danger.public-component {
	color: $danger !important;
	text-decoration: none !important;
}

.tertiary.public-component:hover {
	color: $dark !important;
}
.tertiary.public-component:active {
	color: $tertiary-light !important;
}
.tertiary.public-component {
	color: $tertiary !important;
	text-decoration: none !important;
}
.invalid-feedback {
	position: absolute;
}
.flex {
	display: flex;
	align-items: center;
}
.content-center {
	justify-content: center;
}

.content-between {
	justify-content: space-between;
}

.dropdown-fixed {
	position: fixed !important;
}

.expanded {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0%;
}

.coluna-max-width {
	max-width: 100px;
}

.swal2-icon.swal2-warning.swal2-icon-show {
	font-size: 25px;
}
