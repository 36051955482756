/* ------------------------------------------------------------------------------
 *
 *  # Spinner component overrides
 *
 * ---------------------------------------------------------------------------- */

// Rotating border
.spinner-border-lg {
	--#{$prefix}spinner-width: #{$spinner-width-lg};
	--#{$prefix}spinner-height: #{$spinner-height-lg};
	--#{$prefix}spinner-border-width: #{$spinner-border-width-lg};
}

// Growing circle
.spinner-grow-lg {
	--#{$prefix}spinner-width: #{$spinner-width-lg};
	--#{$prefix}spinner-height: #{$spinner-height-lg};
}
