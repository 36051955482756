/* ------------------------------------------------------------------------------
 *
 *  # Core layout
 *
 *  Content area, sidebar, page header and boxed layout styles
 *
 * ---------------------------------------------------------------------------- */

// Import custom template config
@import '../../../config';
@import '../../../utils/ll-functions';
@import '../../../utils/ll-mixins';

// Core variables and mixins
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Import template's variables
@import '../variables/variables-core';
@import '../variables/variables-custom';
@import '../../../bootstrap_limitless/maps';
@import '../../../bootstrap_limitless/mixins';
@import '../../../bootstrap_limitless/utilities';

// Header
@import '../layout/header';

// Content
@import '../layout/content';

// Sidebar
@import '../layout/sidebar';

// Boxed layout
@import '../layout/boxed';
